.brand {
	display: block;
	margin-right: 28px;
}

.nav-menu-container {
	display: none;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.nav-link {
	display: inline-block;
	margin-bottom: 0px;
	padding-right: 32px;
	padding-left: 32px;
	-webkit-transition: -webkit-transform 800ms ease;
	transition: -webkit-transform 800ms ease;
	transition: transform 800ms ease;
	transition: transform 800ms ease, -webkit-transform 800ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 11px;
	font-weight: 800;
	text-transform: uppercase;
}

.nav-link:hover {
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/Underline-blue-04.png');
	background-position: 50% 100%;
	background-size: 120px;
	background-repeat: no-repeat;
	color: #213a7f;
	font-weight: 800;
}

.nav-link.tab {
	font-size: 13px;
}

.nav-link.web {
	display: block;
}

.div-block {
	padding-top: 5px;
	padding-bottom: 5px;
}

.navbar {
	position: fixed;
	left: 0%;
	top: 20%;
	right: 0%;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	padding-top: 0px;
	padding-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	box-shadow: 7px 7px 14px -10px #000;
}

.navbar.mobile {
	height: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	box-shadow: 7px 7px 14px -13px #000;
	position: static;
}

.navbar.mobile.inside-pages {
	top: 10%;
}

.div-block-40 {
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 5;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: -0.6vh;
	height: 10vh;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(247, 245, 245, 0.5)), to(rgba(247, 245, 245, 0.5))), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-1.png');
	background-image: linear-gradient(180deg, rgba(247, 245, 245, 0.5), rgba(247, 245, 245, 0.5)), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-1.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 1100px;
	background-repeat: repeat, repeat;
	background-attachment: scroll, scroll;
}

.div-block-40.inside-pages {
	top: 0%;
}

.slider-2 {
	position: static;
	display: none;
	height: 80vh;
	margin-top: 0px;
}

.left-arrow {
	margin-top: 0px;
}

.text-block-7 {
	margin-right: auto;
	margin-left: auto;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	font-weight: 700;
}

.right-arrow {
	margin-top: 0px;
}

.slider-caption-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 50%;
	margin-top: 80px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.slide-3 {
	background-image: none;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.heading-4 {
	margin-top: 10px;
	padding: 40px;
	font-family: 'Grand Hotel', sans-serif;
	color: #213a7f;
	font-size: 140px;
	font-weight: 400;
}

.heading-4.two {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	color: #213a7f;
}

.slide-nav-2 {
	border-radius: 0px;
}

.slide-2 {
	background-size: 100%;
	background-repeat: no-repeat;
}

.image-8 {
	margin-top: -15px;
	margin-left: 10px;
	padding-top: 0px;
}

.image-8.blue {
	margin-left: 10px;
}

.image-8.white {
	margin-left: 10px;
}

.inner-block {
	position: absolute;
	left: 45%;
	top: 25%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 750px;
	height: 500px;
	margin-top: -80px;
	padding: 32px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: #fff;
	opacity: 1;
}

.inner-block.outline {
	left: 43%;
	top: 25%;
	width: 750px;
	margin-top: -55px;
	border-style: solid;
	border-width: 5px;
	border-color: #213a7f;
	background-color: transparent;
}

.inner-block.shadow {
	box-shadow: 2px 2px 10px -1px #000;
}

.button-underline {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-left: -20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: translate(0px, 0px);
	-ms-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
	-webkit-transition: -webkit-transform 400ms ease;
	transition: -webkit-transform 400ms ease;
	transition: transform 400ms ease;
	transition: transform 400ms ease, -webkit-transform 400ms ease;
	cursor: pointer;
}

.button-underline.blue-2 {
	width: auto;
}

.button-underline:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.button-underline.hover-box {
	margin-left: 0px;
}

.button-underline.blue.inside-pages {
	margin-top: -30px;
}

.button-underline.inside-pages {
	display: block;
	width: 20%;
}

.button-underline.inside-page {
	margin-top: -20px;
}

.home-content-section {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	opacity: 1;
}

.home-content-section.video {
	padding: 50px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	box-shadow: 0 0 15px -8px #000;
}

.button-link {
	margin-top: 20px;
	padding-top: 0px;
	border-bottom: 4px none #000;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 13px;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	white-space: normal;
	cursor: pointer;
	z-index: 200;
}

.button-link.two {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	margin-left: 0px;
	padding-left: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-transition: -webkit-transform 400ms ease;
	transition: -webkit-transform 400ms ease;
	transition: transform 400ms ease;
	transition: transform 400ms ease, -webkit-transform 400ms ease;
	color: #238425;
	font-weight: 800;
	z-index: 200;
}

.button-link.cookies {
	margin-top: 20px;
}

.illustration-block {
	position: static;
	width: 35%;
	height: 700px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(247, 245, 245, 0.5)), to(rgba(247, 245, 245, 0.5))), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-copy.jpg');
	background-image: linear-gradient(180deg, rgba(247, 245, 245, 0.5), rgba(247, 245, 245, 0.5)), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-copy.jpg');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 2000px;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, fixed;
}

.heading-cursive {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	margin-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Grand Hotel', sans-serif;
	color: #8ab0dd;
	font-size: 62px;
	font-weight: 400;
}

.heading-cursive.recipes {
	display: block;
	padding: 0px;
	color: #fff;
	font-size: 55px;
	text-align: center;
	line-height: 1.2;
	margin-top: 0;
	margin-bottom: 10px;
}

.heading-cursive.recipes:hover {
	display: block;
}

.image-block {
	width: 65%;
	height: 700px;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/halloumi-fries.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.image-block.two {
	position: relative;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/Zaalouk-with-crispy-halloumi-527ab3c.jpg');
	background-position: 0% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.text-block-p {
	margin-bottom: 20px;
	padding-right: 20px;
	padding-left: 20px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	width: 100%;
	text-align: center;
}

.text-block-p.two {
	margin-bottom: 0px;
	padding-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	font-size: 14px;
	text-align: left;
}

.heading {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 60px;
	line-height: 58px;
	font-weight: 800;
	text-align: center;
	text-transform: uppercase;
}

.heading.blue {
	color: #213a7f;
	font-size: 62px;
}

.heading.recipes {
	display: block;
	font-size: 40px;
	line-height: 45px;
}

.heading.recipes:hover {
	display: block;
}

.homepage-slider {
	display: block;
	height: 100vh;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 100% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.image-background {
	width: 65%;
	height: 600px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.image-background.two {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: repeat;
}

.pattern-block {
	width: 35%;
	height: 600px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(247, 245, 245, 0.5)), to(rgba(247, 245, 245, 0.5))), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-1.png');
	background-image: linear-gradient(180deg, rgba(247, 245, 245, 0.5), rgba(247, 245, 245, 0.5)), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-1.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 1000px;
	background-repeat: repeat, repeat-y;
	background-attachment: scroll, scroll;
}

.content-block {
	position: absolute;
	left: 40%;
	top: 5vw;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: #fff;
	box-shadow: 5px 5px 17px -14px #000;
}

.content-block:before {
	content: "";
	border: 4px solid #2db642;
	width: 100%;
	height: 100%;
	position: absolute;
	left: -1.25rem;
	top: 1.25rem;
	z-index: -1;
}

.content-block.two:before {
	left: 1.25rem;
}

.content-block.two {
	left: 10%;
}

.content-border {
	position: absolute;
	left: 38.5%;
	top: 20%;
	width: 50%;
	height: 400px;
	border-style: solid;
	border-width: 4px;
	border-color: #213a7f;
}

.content-border.two {
	left: 12%;
}

.cursive-header {
	margin-bottom: 10px;
	font-family: 'Grand Hotel', sans-serif;
	color: #4a73bc;
	font-size: 46px;
	line-height: 40px;
	font-weight: 400;
}

.cursive-header.slider {
	color: #238425;
	font-size: 110px;
	line-height: 130px;
	text-align: center;
	margin-top: 0px;
}

.regular-header {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
	font-size: 46px;
	font-weight: 800;
	text-transform: uppercase;
}

.cursive-header.video {
	color: #fff;
}

.cursive-header.banner {
	color: #fff;
}

.inner-content-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 30px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	width: 100%;
	align-items: flex-start;
	background-color: #fff;
}

.regular-header {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 46px;
	font-weight: 800;
	text-transform: uppercase;
	line-height: 0.9;
}

.regular-header.white {
	margin-bottom: 30px;
	color: #fff;
	text-align: center;
}

.body-paragraph {
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 14px;
	line-height: 18px;
	width: 100%;
	font-weight: 700;
}

.icon-block {
	width: 150px;
	height: 150px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/icon-1-04.png');
	background-position: 50% 50%;
	background-size: 125px;
	background-repeat: no-repeat;
}

.icon-block.two {
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/icon-2-04.png');
	background-position: 50% 50%;
	background-size: 115px;
	background-repeat: no-repeat;
}

.icon-block.three {
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/icon-3-04.png');
	background-position: 50% 50%;
	background-size: 115px;
	background-repeat: no-repeat;
}

.text-block-h1 {
	margin-bottom: 10px;
	padding: 20px;
	font-family: 'Grand Hotel', sans-serif;
	color: #fff;
	font-size: 50px;
	font-weight: 400;
	text-align: center;
	text-transform: none;
}

.color-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	height: auto;
	max-width: none;
	padding: 32px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #4a73bc;
	-webkit-transition: -webkit-transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: -webkit-transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.color-block:hover {
	box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.59);
	-webkit-transform: scale(1.03) perspective(0px);
	transform: scale(1.03) perspective(0px);
}

.color-block.two {
	background-color: #196b19;
}

.color-block.three {
	background-color: #4a73bc;
}

.icon-image {
	padding-bottom: 10px;
}

.icon-image.cheese {
	margin-top: 25px;
}

.icon-image.media {
	margin-top: 25px;
}

.icon {
	height: 0px;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/Halloumi-Icon-04.png');
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: no-repeat;
}

.home-content-blocks {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 50px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.div-block-46 {
	width: 65%;
	height: 600px;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/Zaalouk-with-crispy-halloumi-527ab3c.jpg');
	background-position: 0px 0px;
	background-size: cover;
	background-repeat: no-repeat;
}

.div-block-47 {
	width: 35%;
	height: 600px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.5)), to(hsla(0, 0%, 100%, 0.5))), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-copy.jpg');
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.5)), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-copy.jpg');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, 2000px;
	background-repeat: repeat, no-repeat;
}

.home-con {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.div-block-48 {
	position: absolute;
	top: 0%;
	width: 50%;
	height: 400px;
	background-color: #fff;
}

.div-block-49 {
	position: absolute;
	top: 1893px;
}

.div-block-31 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 275px;
	margin-top: 50px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15))), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-2-04-min.png'), -webkit-gradient(linear, left top, left bottom, from(#238425), to(#238425));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-2-04-min.png'), linear-gradient(180deg, #238425, #238425);
	background-position: 0px 0px, 50% 50%, 0px 0px;
	background-size: auto, 1500px, auto;
	background-repeat: repeat, repeat-x, repeat;
	background-attachment: scroll, scroll, scroll;
}

.sub-text {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 14px;
	width: 100%;
	font-weight: 700;
	text-align: center;
}

.sub-text.slider {
	font-size: 16px;
}

.one-third-column {
	width: 33.5%;
	height: 300px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.one-third-column.recipe-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 400px;
	padding: 32px 25px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0px 0px, 50% 90%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	cursor: pointer;
}

.one-third-column.recipe-1:hover {
	background-position: 0px 0px, 50% 90%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.one-third-column.recipe-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 400px;
	padding: 32px 25px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	cursor: pointer;
}

.one-third-column.recipe-3:hover {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.one-third-column.recipe-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 400px;
	padding: 33px 25px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-position: 0px 0px, 50% 40%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	cursor: pointer;
}

.one-third-column.recipe-2:hover {
	background-position: 0px 0px, 50% 40%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.column-content {
	overflow: hidden;
	height: 0px;
}

.paragraph-2 {
	display: block;
	overflow: visible;
	padding: 0px 10px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
}

.three-column-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-media-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 20px;
	height: 20px;
	margin-right: 5px;
	margin-left: 5px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 17px;
	background-color: #213a7f;
	opacity: 0.8;
	text-align: center;
}

.social-media-link:hover {
	background-color: #213a7f;
	opacity: 1;
}

.social-media-link.header {
	width: 20px;
	height: 20px;
	margin-right: 5px;
	margin-left: 0px;
	opacity: 0.8;
}

.social-media-link.header:hover {
	background-color: #213a7f;
	opacity: 1;
}

.social-media-link.footer {
	width: 30px;
	height: 30px;
	opacity: 1;
}

.social-media-link.footer:first-of-type {
	margin-left: 0;
}

.footer-social-icon-image {
	background-color: transparent;
}

.footer-social-icon-image:hover {
	background-color: transparent;
}

.footer-link {
	display: inline-block;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-weight: 700;
	text-decoration: none;
	transition: all 0.3s ease;
}

.footer-link:hover {
	color: #4a73bc;
}

.footer-link.email {
	margin-top: 30px;
	text-decoration: underline;
}

.footer-link.email:hover {
	color: #4a73bc;
}

.footer-link.cookies,
.footer-link.privacy {
	text-decoration: underline;
	display: inline-block;
}

.cb-static {
	display: block;
	width: 33.33%;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.cb-static.footer-cb-4 {
	width: 50%;
	padding-right: 0rem;
}

.cb-static.footer-cb-1 {
	width: 50%;
	padding-right: 0.5rem;
}

.cb-static.footer-cb-3 {
	width: 50%;
	padding-right: 0.5rem;
}

.cb-static.footer-cb-left-copy {
	width: 50%;
	padding-right: 0.5rem;
}

.footer-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0%;
	padding-left: 60px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #333;
}

.container-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.container-4.footer-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.cb-heading {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #0a706b;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
}

.cb-heading.footer-heading {
	display: block;
	margin-bottom: 0.5rem;
	font-family: 'Grand Hotel', sans-serif;
	color: #213a7f;
	font-size: 32px;
	line-height: 1.95rem;
	font-weight: 400;
}

.cb-heading.footer-heading.terms {
	margin-top: 40px;
}

.home-content-wrapper {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 3rem;
	padding-left: 3rem;
}

.home-content-wrapper.footer-wrapper {
	max-width: 1300px;
	padding: 32px 50px;
}

.footer-left-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
}

.footer-right-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
}

.social-media-wrapper-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.social-media-wrapper-2.footer-socmed-wrapper {
	margin-top: 0rem;
}

.text-block-p-2 {
	margin-bottom: 20px;
	padding-right: 20px;
	padding-left: 20px;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 16px;
	font-weight: 700;
	text-align: center;
}

.text-block-p-2.two {
	margin-top: 20px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	font-size: 14px;
	text-align: center;
}

.text-block-p-2.two.footer {
	color: #333;
	text-align: left;
}

.home-content-footer {
	border: 1px none #000;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.72)), to(hsla(0, 0%, 100%, 0.72))), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-2-04-min.png');
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.72), hsla(0, 0%, 100%, 0.72)), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-2-04-min.png');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, 1500px;
	background-repeat: repeat, repeat-x;
	background-attachment: scroll, scroll;
}

.footer-content {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 50px;
	padding-left: 50px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: #333;
}

.footer-column-terms {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 140px;
	padding-left: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin: 20px 20px 20px 0px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	text-align: left;
	text-transform: none;
}

.footer-text.sitemap {
	text-decoration: underline;
	text-transform: none;
}

.footer-text.sitemap:hover {
	color: #8ab0dd;
}

.footer-column-rights {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.lightbox-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	border-bottom: 0px !important;
	align-items: center;
}

.video-block-content {
	position: absolute;
	z-index: 1;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	margin-top: 0px;
	padding-top: 0px;
	padding-bottom: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	width: 100%;
	text-align: center;
}

.div-block-39 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	width: 100%;
	height: 80px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.image-9 {
	cursor: pointer;
}

.slide-nav-3 {
	color: transparent;
	font-size: 14px;
}

.div-block-50 {
	position: absolute;
	left: 0;
	top: 48%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 20px 40px 40px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.6)), to(hsla(0, 0%, 100%, 0.6)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.6), hsla(0, 0%, 100%, 0.6));
}

.container-5 {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 650px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.left-arrow-2 {
	display: none;
}

.right-arrow-2 {
	display: none;
}

.text-block-8 {
	color: #213a7f;
	font-size: 13px;
	font-weight: 800;
}

.icon-6 {
	display: block;
	margin-right: 5px;
	padding-left: 0px;
	color: #213a7f;
	font-size: 14px;
}

.dropdown-list {
	margin-top: 0px;
}

.dropdown-list.w--open {
	top: auto;
	margin-top: 2px;
	background-color: #fff;
}

.dropdown-link {
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 0.65px none #ebeaea;
	color: #fff;
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	text-transform: none;
}

.dropdown-link:hover {
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.15)), to(rgba(0, 0, 0, 0.15)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
}

.dropdown-toggle {
	display: block;
	height: auto;
	padding-right: 40px;
	padding-left: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	font-size: 12px;
}

.dropdown-toggle.w--open {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.dropdown-block {
	margin-top: 17.5px;
	background-color: #213a7f;
}

.bold-text-3 {
	color: #1f1e1e;
}

.bold-text-3.video {
	display: none;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-style: italic;
	font-weight: 700;
	text-align: center;
}

.login-link {
	margin-right: 0rem;
	margin-left: 0.5rem;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #f4f4f4;
	font-size: 0.8rem;
	line-height: 1.4rem;
	font-weight: 600;
	letter-spacing: 0.04rem;
	text-decoration: none;
	text-transform: uppercase;
}

.login-link:hover {
	color: #69b5a4;
}

.login-link.language {
	display: none;
	height: 100px;
	margin-top: 0px;
	margin-right: 30px;
	padding-right: 0rem;
	padding-left: 6.5rem;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/5ee3f040f3efc12ceee57082_translate-widget-1.png');
	background-position: 50% 50%;
	background-size: 120px;
	background-repeat: no-repeat;
	color: transparent;
}

.login-link.language:hover {
	opacity: 0.75;
	color: transparent;
}

.gdrp-section-icon {
	position: fixed;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000000000;
	display: block;
	min-height: 100px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 4px none #d3d3d3;
	background-color: #fff;
	box-shadow: 0 -13px 0 0 rgba(179, 182, 183, 0.75);
}

.gdrp-section-icon.just-icon {
	z-index: 100000000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: transparent;
	box-shadow: none;
}

.open-gdrp-icon {
	width: 50px;
	height: 50px;
	margin-right: 4rem;
	padding-right: 0px;
	border-bottom-style: none;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/blue-gear-01.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	-webkit-transition-property: none;
	transition-property: none;
	text-decoration: none;
}

.open-gdrp-icon:hover {
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/light-blue-gear-01.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.cookie-button {
	display: inline-block;
	height: 44px;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 8px 16px 9px;
	border: 2px solid #00283b;
	background-color: #00283b;
	color: #fff;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
}

.cookie-button:hover {
	border-color: #e77c25;
	background-color: #fff;
	color: #00283b;
}

.cookie-button.cookies {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 12px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none;
	border-width: 1px;
	border-color: #a1866c;
	background-color: transparent;
	opacity: 1;
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 700;
}

.cookie-button.cookies:hover {
	border-color: #726869;
	background-color: #16582c;
	color: #fff;
}

.container-2 {
	height: 100%;
	max-width: 1280px;
	min-height: 100%;
	padding-right: 20px;
	padding-left: 20px;
}

.container-2.footer {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.container-2.footer.gdrp {
	position: relative;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.container-2.footer.gdrp.top {
	z-index: 1000;
	padding-top: 30px;
	padding-bottom: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.gdrp-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 65px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 700;
	text-align: center;
}

.gdrp-link:hover {
	color: #8ab0dd;
}

.gdrp-section {
	position: fixed;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000000000;
	display: none;
	min-height: 100px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 4px none #d3d3d3;
	background-color: #333;
	box-shadow: 0 -5px 0 0 #8ab0dd;
}

.gdrp-section.top {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: block;
	background-color: #213a7f;
	box-shadow: none;
}

.cookie-text-para {
	width: 66.66%;
	padding-right: 10px;
	padding-left: 10px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.8rem;
	line-height: 1.3rem;
}

.cookie-text-para.top {
	width: 100%;
	margin-bottom: 0px;
	padding-top: 0px;
	font-size: 14px;
	font-weight: 700;
}

.exit-icon-div {
	position: absolute;
	left: auto;
	top: 1rem;
	right: 1rem;
	bottom: auto;
	width: 25px;
	height: 25px;
	border-bottom-style: none;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/close-icon-lt-grey.svg');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
	opacity: 0.65;
	-webkit-transition-property: none;
	transition-property: none;
	text-decoration: none;
}

.exit-icon-div:hover {
	opacity: 1;
}

.cookie-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	min-width: 425px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.div-block-51 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.social-media-link-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	margin-right: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #213a7f;
	text-align: center;
}

.social-media-link-2:hover {
	background-color: #16582c;
}

.footer-social-icon-image-2 {
	background-color: transparent;
}

.footer-social-icon-image-2:hover {
	background-color: #16582c;
}

.social-media-link-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	margin-right: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	text-align: center;
}

.social-media-link-3:hover {
	background-color: #16582c;
}

.footer-signoff-link {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	opacity: 1;
	-webkit-transition: color 200ms ease;
	transition: color 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 12px;
	font-weight: 700;
	text-decoration: none;
	text-decoration: underline;
	text-transform: none;
	line-height: 16px;
}

.footer-signoff-link:hover {
	border-bottom: none;
	color: #8ab0dd;
}

.footer-signoff-link.w--current {
	color: #8ab0dd;
}

.icon-7 {
	display: block;
	margin-right: 5px;
	font-size: 14px;
}

.dropdown-list-2 {
	background-color: #fff;
}

.text-block-9 {
	color: #213a7f;
}

.search-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-left: 10px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.search {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-bottom: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.search-input {
	width: 150px;
	height: 25px;
	margin-top: 0px;
	margin-bottom: 0px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	font-family: 'Open Sans', sans-serif;
	font-size: 11px;
	line-height: 15px;
	font-weight: 400;
}

.search-button {
	width: 30px;
	height: 30px;
	margin-left: -14px;
	border-radius: 20px;
	background-color: #213a7f;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/search.svg');
	background-position: 50% 50%;
	background-size: 55%;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 12px;
	line-height: 15px;
}

.search-button:hover {
	background-color: #8ab0dd;
}

.image-11 {
	cursor: pointer;
}

.slide-4 {
	background-size: cover, cover;
	background-repeat: no-repeat, repeat;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link.gray-png {
	background-size: 130px 29px;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-gray {
	color: #797676;
}

.footer-signoff-back-top-button {
	margin-left: 1.5rem;
	padding: 0.75em 2.5em 0.75em 1em;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/Arrow2_up_blk.svg');
	background-position: 75% 50%;
	background-size: 7px 5px;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 0 1px #797676;
	opacity: 0.65;
	color: #fff;
	font-size: 0.75rem;
	line-height: 1.25em;
	font-weight: 500;
	text-transform: uppercase;
}

.footer-signoff-back-top-button:hover {
	box-shadow: inset 0 0 0 1px #a09e9c;
	opacity: 1;
}

.text-block-2 {
	color: #fff;
	font-weight: 600;
}

.link {
	color: #fff;
	font-weight: 700;
}

.link:hover {
	color: #d4d1d1;
}

.icon-block-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.alert-block {
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 1000;
	display: block;
	min-height: 100px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #213a7f;
}

.alert-exit-icon {
	position: static;
	top: 1rem;
	right: 1rem;
	width: 25px;
	height: 25px;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/close-icon-lt-grey.svg');
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
}

.div-block-53 {
	display: none;
}

.div-block-54 {
	display: none;
}

.div-block-55 {
	display: none;
}

.mobile-menu-arrow {
	display: none;
}

.main-nav-link {
	position: relative;
	display: block;
	margin-right: 2px;
	margin-left: 2px;
	padding: 0.6rem;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #2d3047;
	font-size: 0.9rem;
	line-height: 1.7rem;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.main-nav-link:hover {
	color: #f29247;
}

.main-nav-link.w--current {
	color: #f29247;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.mobile-menu-arrow-2 {
	display: none;
}

.div-block-57 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.div-block-58 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 10%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.div-block-59 {
	padding: 20px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.2)), to(hsla(0, 0%, 100%, 0.2)));
	background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.2));
}

.alert-block-header {
	position: relative;
	z-index: 10;
	display: none;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.dropdown-wrapper {
	overflow: hidden;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	text-align: center;
}

.main-nav-link-2 {
	position: relative;
	top: auto;
	display: block;
	width: auto;
	margin-right: 5px;
	margin-left: 5px;
	padding: 0rem 30px 20px;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: color 300ms ease;
	transition: color 300ms ease;
	color: #163375;
	font-size: 12px;
	line-height: 1.7rem;
	font-weight: 800;
	text-decoration: none;
	text-transform: uppercase;
}

.main-nav-link-2:hover {
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/Underline-blue-04.png');
	background-position: 50% 100%;
	background-size: 120px;
	background-repeat: no-repeat;
	color: #213a7f;
}

.main-nav-link-2.w--current {
	box-shadow: inset 0 3px 0 0 #0a706b;
	color: #0a706b;
}

.main-nav-link-2.mobile-duplicate {
	display: block;
}

.main-nav-link-2.contact {
	display: none;
}

.main-nav-link-2.mobile {
	display: none;
}

.dropdown-list-3 {
	position: absolute;
	top: 107px;
	overflow: hidden;
	height: 0px;
	max-width: 200px;
	min-width: 200px;
	margin-top: 4px;
	margin-left: 0.75rem;
	padding: 0.5rem;
	background-color: #163375;
	opacity: 0;
	text-align: left;
}

.dropdown-link-2 {
	display: block;
	margin-top: 0px;
	margin-bottom: 2px;
	padding: 10px 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 12px;
	line-height: 1.3rem;
	font-weight: 600;
	text-decoration: none;
}

.dropdown-link-2:hover {
	background-color: #fff;
	color: #163375;
	font-weight: 400;
}

.dropdown-link-2.w--current {
	background-color: #fff;
	color: #0a706b;
}

.two-part-mobile-nav-wrapper-2 {
	display: none;
}

.nav-menu {
	display: block;
	margin-left: 2rem;
	float: right;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	font-weight: 800;
}

.mobile-link-label-2 {
	display: none;
}

.mobile-menu-arrow-3 {
	display: none;
}

.container {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.container.nav-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.container.brand-container {
	display: block;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.brand-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	height: 105px;
	margin-right: 1rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.brand-2.w--current {
	width: 250px;
	margin-right: 2rem;
}

.logo {
	width: 100%;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.div-block-62 {
	position: relative;
	top: -15px;
	display: block;
	height: 35px;
	padding-top: 0px;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/Underline-blue-04.png');
	background-position: 50% 0%;
	background-size: contain;
	background-repeat: no-repeat;
}

.link-block {
	display: block;
	height: 80px;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/play-1-03.png');
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.text-block-10 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
}

.image-12 {
	width: 80px;
	height: 80px;
}

.div-block-63 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	height: 44px;
}

.contact-title {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-right: 20px;
	padding-left: 20px;
	opacity: 0.8;
	color: #213a7f;
	font-size: 12px;
	font-weight: 800;
	text-transform: uppercase;
	cursor: pointer;
}

.contact-title:hover {
	opacity: 1;
}

.search-icon {
	display: none;
	width: 15px;
	height: 15px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 0px 20px;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/blue-search-icon-01.png');
	background-position: 50% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	opacity: 0.8;
	cursor: pointer;
}

.search-icon:hover {
	opacity: 1;
}

.search-icon.mobile {
	display: none;
}

.social-media-icon-header {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 20px;
	padding-left: 20px;
}

.language-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-right: 5px;
	margin-bottom: 0px;
	padding-right: 30px;
	padding-left: 20px;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.text-block-11 {
	opacity: 0.8;
	color: #333;
	font-weight: 400;
	text-decoration: underline;
	text-transform: uppercase;
	cursor: pointer;
}

.text-block-11:hover {
	opacity: 1;
}

.search-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 200px;
	height: 25px;
	margin-top: 0px;
	margin-bottom: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	text-align: right;
}

.search-2.mobile {
	display: none;
}

.search-2.web {
	cursor: pointer;
}

.search-button-2 {
	position: relative;
	z-index: 5;
	width: 25px;
	height: 25px;
	margin-left: -14px;
	padding: 0px;
	border-radius: 20px;
	background-color: #213a7f;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/search.svg');
	background-position: 50% 50%;
	background-size: 45%;
	background-repeat: no-repeat;
	opacity: 1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 12px;
	line-height: 15px;
	cursor: pointer;
}

.search-button-2:hover {
	background-color: #213a7f;
	opacity: 1;
}

.navigation-block {
	position: static;
	display: block;
	height: auto;
}

.search-section-2 {
	display: block;
	width: auto;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.search-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 200px;
	margin-bottom: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.search-input-2 {
	width: 150px;
	height: 30px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	font-size: 12px;
}

.search-button-3 {
	width: 30px;
	height: 30px;
	margin-left: -14px;
	border-radius: 20px;
	background-color: #333;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/search.svg');
	background-position: 50% 50%;
	background-size: 55%;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 12px;
	line-height: 15px;
}

.search-button-3:hover {
	background-color: #333;
}

.search-input-web {
	width: 150px;
	height: 25px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	font-size: 11px;
	line-height: 15px;
	font-weight: 400;
	display: inline;
	margin-bottom: 0px;
}

.search-button-web {
	position: relative;
	z-index: 5;
	width: 25px;
	height: 25px;
	margin-left: -14px;
	padding: 0px;
	border-radius: 20px;
	background-color: #213a7f;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/search.svg');
	background-position: center 7px;
	background-size: 45%;
	background-repeat: no-repeat;
	font-size: 0px;
	display: inline;
}

.inside-page-header {
	position: relative;
	top: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	max-width: none;
	margin-top: 85px;
	padding-right: 6rem;
	padding-left: 6rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: #8ab0dd;
}

.inside-page-header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1200px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	background-color: #882727;
}

.breadcrumbs-list-2 {
	padding-top: 3rem;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.82rem;
	line-height: 1.2rem;
}

.breadcrumbs-list-item-2 {
	display: inline-block;
}

.breadcrumb-link-2 {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.75rem;
	text-decoration: none;
	text-transform: uppercase;
}

.breadcrumb-link-2:hover {
	color: #fff;
}

.breadcrumb-link-2.w--current {
	color: #c66b0e;
}

.breadcrumb-link-2.current-breadcrumb {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
}

.heading-6 {
	margin-top: 0px;
	margin-bottom: 0rem;
	padding-bottom: 3rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	line-height: 42px;
}

.inside-page-content {
	position: relative;
	z-index: 0;
	display: block;
	margin-top: 230px;
	margin-right: 8%;
	margin-left: 8%;
	padding: 1rem 1rem 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	box-shadow: none;
}

.heading-7 {
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-weight: 800;
}

.left-nav-list-link {
	display: block;
	padding: 0.5rem 0.75rem;
	border-bottom: 1px solid rgba(60, 53, 45, 0.15);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #474343;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: uppercase;
}

.left-nav-list-link:hover {
	background-color: transparent;
	box-shadow: none;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #333;
}

.left-nav-list-link.w--current {
	background-color: rgba(0, 0, 0, 0.01);
	box-shadow: inset 3px 0 0 0 #42a644;
	color: #474d47;
}

.intro-paragraph {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.h6-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #333;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 12px;
	line-height: 18px;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.h6-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #4a73bc;
}

.leftnav-holder {
	display: none;
	width: 25%;
	height: auto;
	margin-right: 2rem;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	background-color: transparent;
}

.button-2 {
	display: inline-block;
	margin-top: 0.25rem;
	margin-right: 0.25rem;
	margin-bottom: 0.25rem;
	padding: 0.625rem 1rem;
	border-radius: 4px;
	background-color: #247f3b;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	line-height: 20px;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.03em;
	text-decoration: none;
	text-transform: uppercase;
	text-shadow: none;
}

.button-2:hover {
	background-color: #175326;
	color: #fff;
}

.qt-data-row-text {
	display: inline-block;
	min-width: 20%;
	font-size: 0.85rem;
	text-align: center;
}

.qt-data-row-text.first-col-date {
	min-width: 45%;
	font-family: 'Grand Hotel', sans-serif;
	color: #4a73bc;
	font-size: 38px;
	line-height: 30px;
	font-weight: 400;
	text-align: left;
	text-transform: none;
}

.button-3 {
	display: inline-block;
	width: auto;
	margin: 0.25rem 0.25rem 0.25rem 0rem;
	padding: 0.625rem 1rem;
	border-radius: 4px;
	background-color: #5a5144;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.button-3:hover {
	background-color: #3c352d;
	color: #fff;
}

.unordered-list {
	margin-bottom: 1rem;
}

.h2-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #5a5144;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 700;
	text-decoration: none;
}

.h2-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #4a73bc;
}

.inside-page-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1300px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.inside-page-container.white-background {
	position: relative;
	width: auto;
	max-width: none;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	background-color: #fff;
}

.h5-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #333;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 14px;
	line-height: 20px;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.h5-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #4a73bc;
}

.heading-10 {
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-weight: 800;
	text-transform: uppercase;
}

.paragraph {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	text-decoration: none;
}

.paragraph.list-item {
	margin-bottom: 0rem;
}

.qt-data-rows-wrap {
	margin-top: 0rem;
	margin-bottom: 0rem;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(138, 176, 221, 0.25);
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-size: 0.9375rem;
	line-height: 1.25em;
}

.text-link {
	border-style: none none solid;
	border-width: 0px 0px 2px;
	border-color: transparent transparent #5a5144;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #4a73bc;
}

.content-container {
	display: block;
	width: 50%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background-color: #fff;
}

.content-container.threequarterwidth {
	width: 100%;
}

.qt-data-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.5em 0.75rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	cursor: default;
}

.qt-data-row:hover {
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(40, 41, 115, 0.05)), to(rgba(40, 41, 115, 0.05)));
	background-image: linear-gradient(180deg, rgba(40, 41, 115, 0.05), rgba(40, 41, 115, 0.05));
}

.qt-data-row.title-row {
	display: block;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	background-color: transparent;
	color: #fff;
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 0.03em;
	text-transform: uppercase;
}

.ordered-list {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.left-nav-list-item {
	display: block;
	margin-bottom: 0px;
	list-style-type: disc;
}

.h3-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #333;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;
	text-decoration: none;
}

.h3-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #4a73bc;
}

.left-nav-nested-list {
	margin-bottom: 0px;
	padding-top: 0.375rem;
	padding-bottom: 0rem;
	padding-left: 0.75rem;
	border-top: 1px none rgba(0, 0, 0, 0.09);
}

.block-quote {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #8ab0dd;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #444;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
}

.image-13 {
	width: 100%;
	margin-bottom: 20px;
}

.button-4 {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1.25rem;
	margin-bottom: 1rem;
	padding: 0.5rem 1rem;
	border: 2px solid #3fae2a;
	background-color: #2d7a1e;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-decoration: none;
}

.button-4:hover {
	background-color: #d2e5c6;
	color: #444;
}

.button-4.tertiary {
	margin-top: 0.25rem;
	margin-right: 0rem;
	margin-bottom: 0.25rem;
	padding-top: 0.625rem;
	padding-bottom: 0.625rem;
	border-style: solid;
	border-width: 2px;
	border-color: #247f3b;
	border-radius: 4px;
	background-color: transparent;
	color: #247f3b;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
}

.button-4.tertiary:hover {
	border-color: #247f3b;
	background-color: #247f3b;
	color: #fff;
}

.leftnavigation {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 0px;
	padding-top: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.left-nav-list-link-nested {
	display: block;
	padding: 0.375rem 0.75rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #5a5144;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: none;
}

.left-nav-list-link-nested:hover {
	background-color: rgba(90, 81, 68, 0.1);
	color: #3c352d;
}

.left-nav-list-link-nested.w--current {
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/icon_arrow2_right_blk_50.svg');
	background-position: 0px 53%;
	background-size: 9px 9px;
	background-repeat: no-repeat;
	color: #474d47;
}

.h4-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 2px solid #333;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
	text-decoration: none;
}

.h4-header-link:hover {
	border-bottom-width: 2px;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #4a73bc;
}

.table-div {
	font-family: 'Open Sans', sans-serif;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 0rem;
}

.qt-accordion-item-content-wrap {
	overflow: hidden;
	width: 100%;
	height: auto;
	min-height: 345px;
	padding-right: 0rem;
	padding-left: 0rem;
	float: left;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	box-shadow: 0 1px 20px -14px rgba(0, 0, 0, 0.2);
	font-size: 0.875rem;
	line-height: 1.375em;
}

.heading-8 {
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-weight: 800;
}

.inside-page-header-container-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1200px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.heading-2 {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
	text-transform: none;
}

.main-content {
	display: block;
	width: 100%;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	background-color: transparent;
}

.heading-11 {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 46px;
	line-height: 55px;
	font-weight: 600;
	text-transform: none;
}

.heading-9 {
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-weight: 800;
}

.text-link-2 {
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #196ab5;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.text-link-2:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: #ca4949;
}

.ordered-list-2 {
	padding-left: 20px;
}

.content-button {
	padding: 10px 20px;
	border-style: none;
	border-width: 1px;
	border-color: rgba(210, 210, 210, 0.81);
	border-radius: 0px;
	background-color: #ca4949;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 26px;
	line-height: 30px;
	font-weight: 700;
	letter-spacing: 2px;
}

.content-button:hover {
	border-color: rgba(25, 106, 181, 0.55);
	background-color: #213a7f;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	color: #fff;
}

.heading-link {
	border-bottom: 1px none #fce32e;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #196ab5;
	line-height: 18px;
	text-decoration: underline;
	cursor: pointer;
}

.heading-link:hover {
	border-bottom-color: transparent;
	color: #ca4949;
}

.paragraph-3 {
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 16px;
	line-height: 23px;
}

.block-quote-2 {
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	border-left-color: #196ab5;
	border-top-right-radius: 0px;
	background-color: hsla(0, 0%, 72.9%, 0.18);
	color: #333;
	font-size: 1.35rem;
	line-height: 2rem;
}

.image-2 {
	width: 50%;
	margin-bottom: 10px;
}

.secondary-button {
	margin-right: 20px;
	margin-left: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(25, 106, 181, 0.55);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 13px;
	line-height: 20px;
	font-weight: 800;
	letter-spacing: 0px;
	text-transform: uppercase;
}

.heading-12 {
	text-transform: none;
}

.primary-button {
	margin-right: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(202, 73, 73, 0.32);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #ca4949;
	font-size: 15px;
	line-height: 20px;
	font-weight: 600;
	letter-spacing: 0px;
}

.primary-button:hover {
	background-color: #ca4949;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	color: #fff;
}

.horiz-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(25, 106, 181, 0.55);
	color: #213a7f;
}

.table {
	color: #333;
}

.intro-paragraph-2 {
	margin-bottom: 1.5rem;
	color: #333;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.button-5 {
	display: inline-block;
	min-width: 100px;
	margin-top: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	padding: 0.8rem 1rem 0.7rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-radius: 4px;
	background-color: #fce32e;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2d2e31;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
}

.button-5:hover {
	background-color: #333;
	color: #fce32e;
}

.button-5.tertiary {
	min-width: 0px;
	margin-top: 0px;
	margin-right: 0rem;
	margin-bottom: 0px;
	padding: 10px 20px;
	border: 1px solid #c4ccd1;
	border-radius: 0px;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #4a73bc;
	font-size: 13px;
	line-height: 20px;
	font-weight: 800;
	text-transform: uppercase;
}

.button-5.tertiary:hover {
	background-color: #ebeaea;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #4a73bc;
}

.heading-13 {
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
	font-weight: 600;
	text-transform: none;
}

.breadcrumbs-wrapper {
	display: block;
	max-width: 1500px;
	margin-bottom: 30px;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumbs-list {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #196ab5;
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
	color: #333;
}

.breadcrumb-link {
	border-bottom: 1px none #196ab5;
	opacity: 0.69;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #333;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent;
	opacity: 1;
	color: #333;
}

.breadcrumb-link.current-bc-link {
	border-bottom-style: none;
	opacity: 1;
	color: #213a7f;
	font-weight: 700;
}

.breadcrumb-link.current-bc-link:hover {
	color: #4a73bc;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #213a7f;
	font-size: 1rem;
}

.body {
	background-color: rgba(51, 51, 51, 0.04);
}

.left-nav-wrapper {
	overflow: hidden;
	min-width: 256px;
	width: 256px;
	height: auto;
	margin-right: 2rem;
	padding-bottom: 0rem;
	border-style: none;
	border-width: 1px;
	border-color: rgba(138, 176, 221, 0.15);
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
	background-image: linear-gradient(180deg, #fff, #fff);
}

.left-nav-section-title {
	margin-top: 0px;
	margin-bottom: 0px;
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
	font-family: 'Grand Hotel', sans-serif;
	line-height: 50px;
	font-weight: 400;
	text-transform: none;
}

.left-nav-section-title-link {
	display: block;
	padding: 0.75rem 0px 0.75rem 0.5rem;
	border-style: solid !important;
	border-width: 1px !important;
	border-color: rgba(51, 51, 51, 0.2) !important;
	background-color: transparent;
	color: #238425 !important;
	font-size: 2.4rem !important;
	line-height: 2.4rem !important;
	font-weight: 400 !important;
	font-family: 'Grand Hotel', sans-serif !important;
	text-decoration: none !important;
}

.child-wrap {
	padding-bottom: 0px;
	padding-left: 0rem;
	border-bottom: 1px none #000;
}

.left-nav-link-3 {
	display: block;
	width: 100%;
	padding: 15px 0.125rem 15px 0.5rem;
	border-style: none solid solid;
	border-width: 1px;
	border-color: #000 rgba(51, 51, 51, 0.2) rgba(51, 51, 51, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #238425;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-3:hover {
	padding-right: 0rem;
	padding-left: 0.5rem;
	border-left: 4px solid #2db642;
	color: #2db642;
}

.left-nav-link-3.w--current {
	padding: 15px 0.5rem;
	border-style: none none solid;
	border-width: 1px;
	border-color: #000 #000 rgba(25, 106, 181, 0.19);
	border-radius: 0px;
	background-color: transparent;
	box-shadow: none;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #196ab5;
	text-transform: none;
}

.left-nav-link-3.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-3.grandchild-link {
	padding: 15px 0.125rem 15px 2rem;
	border-style: none solid solid;
	border-width: 1px;
	border-color: #000 rgba(51, 51, 51, 0.2) rgba(51, 51, 51, 0.2);
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-size: 0.9rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-transform: none;
}

.left-nav-link-3.grandchild-link:hover {
	border-left: 4px solid rgba(138, 176, 221, 0.5);
}

.left-nav-link-3.grandchild-link.w--current {
	padding-top: 15px;
	padding-bottom: 15px;
	background-image: none;
	font-weight: 600;
}

.left-nav-link-3.greatgrandchild-link {
	width: auto;
	padding: 15px 0.125rem 15px 3rem;
	border-style: none solid solid;
	border-width: 1px;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	font-size: 0.8rem;
	line-height: 1.13rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-3.greatgrandchild-link:hover {
	border-left: 4px solid rgba(138, 176, 221, 0.5);
	color: #4a73bc;
}

.left-nav-link-3.greatgrandchild-link.w--current {
	margin-right: 0rem;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 3rem;
	border-left: 1px solid rgba(51, 51, 51, 0.2);
	border-bottom-style: solid;
	border-bottom-color: rgba(51, 51, 51, 0.2);
	background-image: none;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #2db642;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 400;
}

.button-styling {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-left: 12px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.tertiary-button-style {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.secondary-button-style {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.right-nav {
	width: 21%;
	max-width: 21%;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
	font-style: normal;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.sidebar-section-title {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-style: normal;
	font-weight: 800;
}

.sidebar-list {
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
	color: #213a7f;
}

.sidebar-list-item:hover {
	color: #4a73bc;
}

.link-3 {
	border-style: solid solid none;
	border-width: 1px;
	border-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #213a7f;
	font-size: 1rem;
	text-decoration: none;
}

.link-3:hover {
	border-bottom: 0px none rgba(156, 203, 60, 0);
	color: #4a73bc;
}

.link-3.w--current {
	color: #28313b;
	font-weight: 700;
}

.body-2 {
	background-color: rgba(51, 51, 51, 0.04);
}

.paragraph-4 {
	font-family: 'Open Sans', sans-serif;
}

.body-3 {
	background-color: rgba(51, 51, 51, 0.04);
}

.body-4 {
	background-color: rgba(51, 51, 51, 0.04);
}


.div-block-64 {
	position: relative;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 5;
	height: 1px;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(51, 51, 51, 0.01)), to(rgba(51, 51, 51, 0.01)));
	background-image: linear-gradient(180deg, rgba(51, 51, 51, 0.01), rgba(51, 51, 51, 0.01));
	box-shadow: 4px 4px 4px 0 #000;
}

.section-2 {
	position: absolute;
	left: auto;
	top: 2704px;
	z-index: 5;
	width: 100%;
	height: 0.1px;
	background-color: #333;
	box-shadow: 1px 1px 3px 0 #000;
	opacity: 1;
}

.container-6 {
	max-width: 1424px;
	margin-right: auto;
	margin-left: auto;
}

.container-6.inside-page-content-container {
	display: block;
	max-width: 1300px;
	min-height: 500px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.container-6.inside-page-content-container.page-title-container {
	position: relative;
	min-height: 0px;
}

.inside-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 3%;
	margin-left: 3%;
	padding: 1rem 1rem 0rem;
	background-color: #fff;
}

.inside-content-wrapper.page-title-container {
	position: absolute;
	left: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 0rem 0%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-color: transparent;
}

.h1-page-title {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	font-size: 3.5rem;
	line-height: 4.5rem;
	font-weight: 400;
	text-align: center;
	text-transform: none;
}

.inside-page-banner-section {
	position: relative;
	display: block;
	width: 100%;
	height: 576px;
	margin-right: auto;
	margin-left: auto;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/Grilling_520421898.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-page-banner-section.banner-image-1 {
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/veg-in-collander-tiny_1903x926.jpg');
}

.page-title-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 3%;
	margin-left: 3%;
	padding-right: 10%;
	padding-left: 10%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-bottom: 4px solid #d8263c;
	background-color: hsla(0, 0%, 100%, 0.8);
}

.inside-page-header-section {
	position: relative;
	display: block;
	height: 18.23vw;
	background-color: #c9c6c4;
	background-position: 100% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.terms-footer-links {
	list-style-type: none;
	padding: 0;
}

/* media queries */
@media screen and (max-width: 991px) {
	.button-underline.blue-2 .image-8.blue {
		margin-top: -15px;
		width: 120px;
		max-width: none;
	}

	.menu-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 68px;
		height: 68px;
		margin-top: 15px;
		margin-right: 28px;
		padding: 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #fff;
	}

	.menu-button.w--open {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: transparent;
	}

	.nav-menu-container {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		background-color: #213a7f;
	}

	.nav-link {
		display: block;
		width: 90%;
		margin-right: 0px;
		margin-left: 0px;
		padding: 20px 0px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border: 1px none #000;
		background-color: transparent;
		-webkit-transform: translate(0px, 0px);
		-ms-transform: translate(0px, 0px);
		transform: translate(0px, 0px);
		-webkit-transition: opacity 200ms ease, -webkit-transform 800ms ease;
		transition: opacity 200ms ease, -webkit-transform 800ms ease;
		transition: opacity 200ms ease, transform 800ms ease;
		transition: opacity 200ms ease, transform 800ms ease, -webkit-transform 800ms ease;
		color: #fff;
		font-weight: 700;
	}

	.nav-link:hover {
		display: block;
		background-color: transparent;
		background-image: none;
		background-position: 0px 0px;
		color: #fff;
		font-weight: 700;
	}

	.nav-link.tab {
		padding-left: 20px;
	}

	.nav-link.tab:hover {
		background-image: none;
		background-position: 0px 0px;
	}

	.image-10 {
		margin-top: -5px;
		padding: 9px;
	}

	.navbar {
		display: block;
		margin-right: 0px;
		margin-left: 0px;
		padding: 5px 0px 0px 40px;
	}

	.navbar.mobile {
		top: 20%;
		display: block;
		padding-top: 0px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.icon-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		-webkit-transition: opacity 300ms ease;
		transition: opacity 300ms ease;
		font-family: 'Open Sans', sans-serif;
		color: #213a7f;
		font-size: 38px;
		font-weight: 300;
		text-align: left;
	}

	.icon-2:hover {
		color: #4a73bc;
	}

	.menu-button-text {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: -8px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-family: 'Open Sans', sans-serif;
		color: #213a7f;
		font-size: 0.7rem;
		line-height: 1rem;
		font-weight: 700;
		text-align: center;
	}

	.menu-button-text:hover {
		color: #213a7f;
	}

	.div-block-40 {
		top: 10%;
	}

	.text-block-7 {
		font-size: 14px;
	}

	.heading-4 {
		display: block;
		padding: 25px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		font-size: 100px;
	}

	.slide-2 {
		background-size: 1275px;
		background-repeat: no-repeat;
	}

	.image-8.blue {
		margin-left: 15px;
	}

	.image-8.white {
		display: inline-block;
		max-width: 70%;
		margin-top: -12px;
		margin-left: 0px;
	}

	.inner-block {
		left: 10%;
		width: 600px;
		height: 400px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.inner-block.outline {
		left: 8%;
		width: 600px;
	}

	.inner-block.shadow {
		box-shadow: 0 0 10px -1px #000;
	}

	.button-underline {
		margin-top: 5px;
		margin-left: -27px;
	}

	.button-link {
		position: static;
		margin-top: 20px;
		font-size: 11px;
	}

	.button-link.two {
		font-size: 12px;
	}

	.illustration-block {
		height: 600px;
	}

	.heading-cursive {
		font-size: 52px;
		text-align: left;
	}

	.heading-cursive.recipes {
		margin-bottom: 5px;
		font-size: 38px;
		line-height: 30px;
	}

	.image-block {
		height: 600px;
	}

	.image-block.two {
		height: 600px;
		background-position: 25% 50%;
		background-size: cover;
	}

	.text-block-p {
		margin-bottom: 0px;
		padding-right: 10px;
		padding-left: 10px;
		font-size: 12px;
		line-height: 14px;
	}

	.text-block-p.two {
		line-height: 16px;
	}

	.heading {
		font-size: 50px;
	}

	.heading.blue {
		font-size: 52px;
		line-height: 52px;
		text-align: left;
	}

	.heading.recipes {
		font-size: 25px;
		line-height: 25px;
	}

	.content-block.two {
		top: 15%;
	}

	.content-border {
		left: 37%;
	}

	.content-border.two {
		left: 13%;
		top: 19%;
		height: 425px;
	}

	.regular-header.white {
		margin-bottom: 30px;
	}

	.icon-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 150px;
		height: 125px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/icon-1-04.png');
		background-position: 50% 50%;
		background-size: 100px;
		background-repeat: no-repeat;
	}

	.icon-block.two {
		height: 125px;
		background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/icon-2-04.png');
		background-position: 50% 50%;
		background-size: 90px;
		background-repeat: no-repeat;
	}

	.icon-block.three {
		background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/icon-3-04.png');
		background-position: 50% 50%;
		background-size: 90px;
		background-repeat: no-repeat;
	}

	.text-block-h1 {
		margin-bottom: 10px;
		padding: 10px;
		font-size: 38px;
	}

	.color-block {
		height: 300px;
		padding: 0px 10px 10px;
	}

	.icon-image {
		display: inline-block;
		max-width: 50%;
		margin-right: auto;
		margin-left: auto;
	}

	.div-block-31 {
		min-height: 250px;
		background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-1.png'), -webkit-gradient(linear, left top, left bottom, from(#213a7f), to(#213a7f));
		background-image: linear-gradient(180deg, transparent, transparent), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-1.png'), linear-gradient(180deg, #213a7f, #213a7f);
		background-position: 0px 0px, 0px 0px, 0px 0px;
		background-size: auto, 1000px, auto;
		background-repeat: repeat, no-repeat, repeat;
		background-attachment: scroll, scroll, scroll;
	}

	.sub-text {
		padding-right: 60px;
		padding-left: 60px;
		text-align: center;
	}

	.one-third-column.recipe-1 {
		height: 250px;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.one-third-column.recipe-3 {
		height: 250px;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.one-third-column.recipe-2 {
		height: 250px;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.column-content {
		overflow: hidden;
	}

	.paragraph-2 {
		font-size: 14px;
	}

	.social-media-link {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
		background-color: #8ab0dd;
		opacity: 1;
	}

	.social-media-link:hover {
		background-color: #8ab0dd;
	}

	.social-media-link.header {
		width: 2rem;
		height: 2rem;
		margin-right: 0.5rem;
		background-color: #8ab0dd;
		opacity: 1;
	}

	.social-media-link.header:hover {
		background-color: #8ab0dd;
	}

	.cb-static.footer-cb-4 {
		display: block;
	}

	.cb-static.footer-cb-3 {
		width: 50%;
		padding-right: 5rem;
	}

	.container-4 {
		max-width: 991px;
	}

	.container-4.footer-content-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.cb-heading.footer-heading {
		font-size: 2rem;
		line-height: 1.5rem;
	}

	.home-content-wrapper {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.home-content-wrapper.footer-wrapper {
		display: block;
		padding-right: 2rem;
		padding-left: 2.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-left-content-wrap {
		width: 100%;
	}

	.footer-right-content-wrap {
		width: 100%;
	}

	.social-media-wrapper-2.footer-socmed-wrapper {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.text-block-p-2 {
		margin-bottom: 0px;
		padding-right: 10px;
		padding-left: 10px;
		font-size: 12px;
		line-height: 14px;
	}

	.text-block-p-2.two {
		font-size: 13px;
		line-height: 16px;
		font-weight: 700;
	}

	.home-content-footer {
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.72), hsla(0, 0%, 100%, 0.72)), url('../images/pattern-2-04-min.png');
		background-repeat: repeat, repeat-y;
	}

	.footer-column-terms {
		width: 20%;
		padding-right: 0px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.footer-text {
		margin-right: 10px;
		font-size: 11px;
	}

	.footer-text.sitemap {
		margin-left: 5px;
		font-size: 11px;
	}

	.footer-column-rights {
		width: 80%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		font-size: 12px;
	}

	.video-block-content {
		height: 100%;
		padding: 25px;
	}

	.div-block-39 {
		width: 70px;
		height: 70px;
	}

	.text-block-8 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-left: 20px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		color: #fff;
	}

	.dropdown-list {
		display: none;
	}

	.dropdown-list.w--open {
		position: static;
		left: 0px;
		display: block;
	}

	.dropdown-link {
		padding-right: 0px;
		padding-left: 20px;
	}

	.dropdown-toggle {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.dropdown-toggle.w--open {
		display: block;
		padding-right: 20px;
		padding-left: 0px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.dropdown-block {
		display: block;
		width: auto;
		margin-top: 0px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		background-color: #213a7f;
	}

	.bold-text-3 {
		color: #1f1e1e;
	}

	.open-gdrp-icon {
		margin-right: 0.5rem;
	}

	.container-2.footer.gdrp {
		padding-top: 1rem;
		padding-bottom: 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container-2.footer.gdrp.top {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.gdrp-link {
		margin-right: 1rem;
	}

	.cookie-text-para {
		width: 90%;
		margin-bottom: 0.25rem;
		font-size: 0.75rem;
		line-height: 1.25rem;
	}

	.cookie-text-para.top {
		width: auto;
		padding-top: 0px;
	}

	.exit-icon-div {
		right: 1rem;
	}

	.cookie-div {
		width: 90%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.social-media-link-2 {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
		background-color: #0a706b;
	}

	.social-media-link-3 {
		width: 2rem;
		height: 2rem;
		margin-right: 1rem;
		margin-bottom: 1rem;
		margin-left: 0rem;
		background-color: #0a706b;
	}

	.footer-signoff-link {
		margin-right: 5px;
		margin-left: 5px;
		font-size: 11px;
	}

	.icon-7 {
		color: #213a7f;
	}

	.text-block-9 {
		color: #fff;
	}

	.search-section {
		display: block;
	}

	.search {
		width: auto;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.search-input {
		position: relative;
		z-index: 1000;
		display: block;
		overflow: hidden;
		width: 0px;
		height: 30px;
		margin-bottom: 0px;
		opacity: 0;
	}

	.search-button {
		position: relative;
		z-index: 1100;
		background-color: #333;
		color: #333;
	}

	.footer-signoff-back-top-button {
		margin-left: 0rem;
		font-size: 0.75rem;
	}

	.menu-button-2 {
		position: static;
		top: 25%;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 68px;
		height: 68px;
		padding: 10px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: rgba(32, 28, 15, 0);
		-webkit-transform: translate(0px, 25%);
		-ms-transform: translate(0px, 25%);
		transform: translate(0px, 25%);
		-webkit-transition: background-color 300ms ease;
		transition: background-color 300ms ease;
		color: #16582c;
	}

	.menu-button-2:hover {
		background-color: transparent;
		color: #a1866c;
	}

	.menu-button-2.w--open {
		background-color: #000;
	}

	.mobile-menu-icon {
		font-size: 2.5rem;
		line-height: 2.5rem;
		text-align: center;
	}

	.menu-button-text-2 {
		margin-top: -8px;
		font-size: 0.7rem;
		line-height: 1rem;
		text-align: center;
	}

	.navigation-link-dropdown {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.navigation-link-dropdown:hover {
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	}

	.div-block-53 {
		display: block;
		width: 100px;
		height: auto;
		border-style: solid;
		border-width: 1px;
		border-color: hsla(0, 0%, 100%, 0.5);
		background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 20px;
		background-repeat: no-repeat;
	}

	.navigation-link {
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}

	.navigation-link:hover {
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	}

	.div-block-54 {
		display: block;
		width: 10%;
		background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 15px;
		background-repeat: no-repeat;
	}

	.div-block-55 {
		display: block;
		width: 10%;
		height: auto;
		padding-top: 0px;
		background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 15px;
		background-repeat: no-repeat;
	}

	.mobile-menu-arrow {
		position: static;
		display: block;
		width: 10%;
		border-left: 1px solid hsla(0, 0%, 100%, 0.25);
		border-bottom-color: rgba(31, 48, 79, 0.2);
		background-color: transparent;
	}

	.mobile-menu-arrow:hover {
		background-color: transparent;
	}

	.div-block-56 {
		border-left: 1px none #fff;
		background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/white-arrow-rounded_1white-arrow-rounded.png');
		background-position: 50% 50%;
		background-size: 8px;
		background-repeat: no-repeat;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}

	.two-part-mobile-nav-wrapper:hover {
		background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(rgba(0, 0, 0, 0.2)));
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
		color: #2d3047;
	}

	.main-nav-link {
		padding: 1.3rem 1rem 1rem;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		color: #fff;
		line-height: 1rem;
		font-weight: 600;
		text-align: left;
	}

	.main-nav-link:hover {
		background-color: transparent;
		color: #f29247;
	}

	.main-nav-link.w--current {
		background-color: transparent;
		box-shadow: none;
		color: #f29247;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		margin-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		-webkit-transition-property: all;
		transition-property: all;
		text-align: left;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: transparent;
		color: #f29247;
	}

	.mobile-link-label {
		display: inline-block;
		margin-bottom: 0rem;
		line-height: 1rem;
	}

	.mobile-menu-arrow-2 {
		display: block;
		width: 12%;
		border-left: 1px solid hsla(0, 0%, 100%, 0.25);
		border-bottom-color: rgba(31, 48, 79, 0.2);
		background-color: transparent;
		background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/white-arrow-rounded_1white-arrow-rounded.png');
		background-position: 50% 50%;
		background-size: 8px;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	.mobile-menu-arrow-2:hover {
		background-color: transparent;
	}

	.div-block-57 {
		display: block;
	}

	.div-block-58 {
		display: block;
	}

	.dropdown-wrapper {
		display: none;
	}

	.main-nav-link-2 {
		display: block;
		margin-right: 0px;
		margin-left: 0px;
		padding: 1.3rem 1rem 1rem;
		border-top: 1px none hsla(0, 0%, 100%, 0.25);
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		color: #fff;
		line-height: 1rem;
		font-weight: 600;
	}

	.main-nav-link-2:hover {
		background-color: #fff;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		color: #213a7f;
		font-weight: 600;
	}

	.main-nav-link-2.w--current {
		background-color: #163375;
		box-shadow: inset 0 3px 0 0 #e3ac50;
		color: #f3f2ee;
	}

	.main-nav-link-2.mobile-duplicate {
		display: block;
		width: 88%;
		margin-right: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		-webkit-transition-property: all;
		transition-property: all;
	}

	.main-nav-link-2.mobile-duplicate:hover {
		font-weight: 600;
	}

	.main-nav-link-2.contact {
		display: block;
		border-top-style: solid;
	}

	.main-nav-link-2.mobile {
		display: none;
	}

	.two-part-mobile-nav-wrapper-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}

	.two-part-mobile-nav-wrapper-2:hover {
		color: #2d3047;
	}

	.nav-menu {
		left: auto;
		top: auto;
		display: block;
		width: 90%;
		margin-top: 105px;
		background-color: #163375;
		text-align: left;
	}

	.mobile-link-label-2 {
		display: inline-block;
		margin-bottom: 0rem;
		line-height: 1rem;
		font-weight: 600;
	}

	.mobile-link-label-2:hover {
		font-weight: 600;
	}

	.mobile-menu-arrow-3 {
		display: block;
		width: 12%;
		border-left: 1px solid hsla(0, 0%, 100%, 0.25);
		border-bottom-color: rgba(31, 48, 79, 0.2);
		background-color: #163375;
		background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/white-arrow-rounded_1white-arrow-rounded.png');
		background-position: 50% 50%;
		background-size: 8px;
		background-repeat: no-repeat;
	}

	.mobile-menu-arrow-3:hover {
		background-color: #4a73bc;
	}

	.container {
		max-width: 991px;
	}

	.container.nav-container {
		display: block;
		max-width: 940px;
		margin-top: 0px;
	}

	.container.brand-container {
		display: block;
		max-width: none;
	}

	.brand-2 {
		width: 225px;
	}

	.brand-2.w--current {
		width: 225px;
	}

	.image-12 {
		width: 70px;
		height: 70px;
	}

	.div-block-63 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 1.3rem 1rem 1rem 0.2rem;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.contact-title {
		opacity: 1;
		color: #fff;
		font-weight: 600;
	}

	.search-icon {
		display: none;
	}

	.search-icon.mobile {
		display: block;
		width: 50px;
		height: 50px;
		margin-top: 15px;
		margin-right: 10px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-size: 20px;
		opacity: 1;
	}

	.contact-block {
		display: none;
	}

	.social-media-icon-header {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0px;
		padding-top: 0px;
		padding-left: 10px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.language-block {
		margin-left: auto;
		color: #fff;
	}

	.text-block-11 {
		opacity: 1;
	}

	.mobile-menu-block {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.search-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: auto;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.search-2.mobile {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 15px;
		padding-right: 20px;
		padding-left: 20px;
	}

	.search-2.web {
		display: none;
	}

	.search-button-2 {
		position: relative;
		z-index: 1100;
		width: 30px;
		height: 30px;
		background-color: #213a7f;
		color: #213a7f;
	}

	.search-section-2 {
		display: block;
	}

	.search-3 {
		width: auto;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.search-input-2 {
		position: relative;
		z-index: 1000;
		display: block;
		overflow: hidden;
		width: 0px;
		margin-bottom: 0px;
		opacity: 0;
	}

	.search-button-3 {
		position: relative;
		z-index: 1100;
		background-color: #333;
		color: #333;
	}

	.inside-page-header-container {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.inside-page-content {
		margin-top: 260px;
		margin-right: 5%;
		margin-left: 5%;
	}

	.leftnav-holder {
		display: none;
	}

	.button-3 {
		margin-bottom: 1rem;
		margin-left: 0rem;
	}

	.inside-page-container.white-background {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.qt-data-rows-wrap {
		min-height: auto;
		margin-bottom: 1rem;
	}

	.content-container {
		width: 100%;
		padding-left: 0rem;
	}

	.content-container.threequarterwidth {
		width: 100%;
	}

	.leftnavigation {
		width: 100%;
		margin-top: -80px;
		margin-bottom: 0px;
		padding-top: 120px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.qt-accordion-item-content-wrap {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.inside-page-header-container-2 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.main-content {
		width: 100%;
		padding-top: 1rem;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.secondary-button {
		font-size: 12px;
		line-height: 17px;
	}

	.primary-button {
		font-size: 12px;
		line-height: 17px;
	}

	.intro-paragraph-2 {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	.button-5.tertiary {
		font-size: 12px;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		padding-left: 2rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.left-nav-wrapper {
		display: none;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.container-6 {
		max-width: 991px;
	}

	.container-6.inside-page-content-container {
		position: relative;
		max-width: 991px;
		min-height: 0px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container-6.inside-page-content-container.page-title-container {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.inside-content-wrapper {
		margin-right: 2%;
		margin-left: 2%;
	}

	.inside-content-wrapper.page-title-container {
		max-width: 991px;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.h1-page-title {
		font-size: 2.5rem;
		line-height: 3.5rem;
	}

	.inside-page-banner-section {
		height: 347px;
	}

	.page-title-inner-wrap {
		margin-right: 2%;
		margin-left: 2%;
		padding-right: 3%;
		padding-left: 3%;
	}

	.cursive-header.slider {
		font-size: 90px;
		line-height: 110px;
	}

	.pattern-block,
	.image-background {
		height: 640px;
	}

	.button-underline.hover-box {
		margin-bottom: 10px;
	}

	.button-underline.hover-box .button-link {
		margin-top: 10px;
	}
}

@media screen and (max-width: 767px) {
	.brand {
		margin-top: 0px;
	}

	.menu-button.w--open {
		margin-top: 15px;
	}

	.navbar.mobile {
		padding-top: 0px;
	}

	.text-block-7 {
		padding-right: 100px;
		padding-left: 100px;
		font-size: 14px;
		line-height: 15px;
		text-align: center;
	}

	.heading-4 {
		font-size: 80px;
		line-height: 20px;
	}

	.slide-2 {
		background-size: 1275px;
	}

	.bold-text-2 {
		color: #333;
	}

	.image-8.blue {
		margin-left: 15px;
	}

	.inner-block {
		left: 10%;
		width: 450px;
		height: 400px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		text-align: left;
	}

	.inner-block.outline {
		left: 6%;
		width: 450px;
	}

	.button-underline {
		margin-top: 3px;
		margin-left: -27px;
	}

	.button-underline.gdpr {
		margin-left: 40px;
	}

	.home-content-section.video {
		padding: 32px;
	}

	.button-link.two {
		margin-left: 0px;
	}

	.heading-cursive.recipes {
		font-size: 40px;
		line-height: 40px;
	}

	.image-block.two {
		background-position: 25% 50%;
		background-size: cover;
	}

	.text-block-p {
		padding-right: 20px;
		padding-left: 20px;
		font-size: 11px;
	}

	.text-block-p.two {
		font-size: 12px;
	}

	.heading {
		font-size: 52px;
		line-height: 48px;
	}

	.heading.blue {
		margin-bottom: 20px;
		font-size: 52px;
		line-height: 48px;
		text-align: left;
	}

	.heading.recipes {
		font-size: 32px;
		line-height: 30px;
	}

	.content-block {
		left: 13%;
		width: 75%;
	}

	.content-block.two {
		top: 14%;
		width: 75%;
	}

	.content-border {
		left: 10%;
		width: 75%;
	}

	.content-border.two {
		top: 18%;
		width: 75%;
	}

	.cursive-header {
		font-size: 40px;
	}

	.cursive-header.slider {
		font-size: 92px;
		line-height: 107px;
	}

	.regular-header.white {
		margin-bottom: 20px;
		font-size: 40px;
		text-align: center;
	}

	.icon-block {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.icon-block.three {
		height: 120px;
	}

	.text-block-h1 {
		margin-bottom: 0px;
	}

	.color-block {
		width: 100%;
		height: 300px;
	}

	.color-block.two {
		padding-right: 20px;
		padding-left: 20px;
	}

	.home-content-blocks {
		padding: 32px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.div-block-31 {
		padding-right: 32px;
		padding-left: 32px;
		background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(transparent)), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-1.png'), -webkit-gradient(linear, left top, left bottom, from(#213a7f), to(#213a7f));
		background-image: linear-gradient(180deg, transparent, transparent), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/pattern-1.png'), linear-gradient(180deg, #213a7f, #213a7f);
		background-position: 0px 0px, 0px 0px, 0px 0px;
		background-size: auto, 1000px, auto;
		background-repeat: repeat, repeat-y, repeat;
		background-attachment: scroll, scroll, scroll;
	}

	.sub-text {
		font-size: 14px;
		text-align: center;
	}

	.sub-text.slider {
		padding-right: 32px;
		padding-left: 32px;
	}

	.one-third-column.recipe-1 {
		width: 100%;
		height: 250px;
		margin-right: auto;
		margin-left: auto;
	}

	.one-third-column.recipe-3 {
		width: 100%;
		height: 250px;
	}

	.one-third-column.recipe-2 {
		width: 100%;
		height: 250px;
	}

	.three-column-section {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.social-media-link {
		margin-right: 0.3rem;
		margin-left: 0.3rem;
	}

	.cb-static {
		width: 33.33%;
	}

	.cb-static.footer-cb-1 {
		width: 50%;
	}

	.cb-static.footer-cb-3 {
		padding-right: 2rem;
	}

	.cb-static.footer-cb-left-copy {
		width: 50%;
	}

	.container-4 {
		max-width: 767px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.container-4.footer-content-container {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 1.5rem;
		padding-left: 2rem;
	}

	.footer-left-content-wrap {
		width: 100%;
		border-bottom: 1px solid rgba(255, 248, 225, 0.25);
	}

	.footer-right-content-wrap {
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid rgba(255, 248, 225, 0.25);
	}

	.text-block-p-2 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.text-block-p-2.two {
		font-size: 12px;
	}

	.footer-content {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-column-terms {
		width: 50%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-text {
		margin-bottom: 15px;
	}

	.footer-text.sitemap {
		margin-top: 10px;
		margin-right: 0px;
		margin-bottom: 10px;
	}

	.footer-column-rights {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.video-block-content {
		height: 90%;
	}

	.div-block-39 {
		width: 60px;
		height: 60px;
	}

	.bold-text-3 {
		font-size: 14px;
	}

	.container-2 {
		padding-right: 10px;
		padding-left: 10px;
	}

	.gdrp-section {
		display: none;
	}

	.cookie-text-para {
		width: 85%;
	}

	.cookie-text-para.top {
		margin-right: 10px;
		padding-top: 0px;
		color: #fff;
		font-size: 12px;
	}

	.exit-icon-div {
		width: 20px;
		height: 20px;
	}

	.cookie-div {
		width: 85%;
		padding-right: 10px;
		padding-left: 10px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.social-media-link-2 {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.social-media-link-3 {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.footer-signoff-link {
		margin-bottom: 10px;
	}

	.footer-signoff-back-top-button {
		padding-top: 1em;
		padding-bottom: 1em;
		font-size: 0.625rem;
		line-height: 1.5em;
	}

	.alert-block {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		z-index: 1000;
		min-height: 100px;
		background-color: #213a7f;
	}

	.alert-exit-icon {
		width: 20px;
		height: 20px;
	}

	.mobile-menu-arrow {
		width: 12%;
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 100%;
		padding-top: 2rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.mobile-link-label {
		text-align: left;
	}

	.mobile-menu-arrow-2 {
		width: 12%;
	}

	.main-nav-link-2.mobile-duplicate {
		width: 88%;
	}

	.mobile-menu-arrow-3 {
		width: 12%;
	}

	.container {
		max-width: 767px;
	}

	.container.nav-container {
		max-width: 728px;
	}

	.container.brand-container {
		max-width: 728px;
	}

	.brand-2 {
		margin-left: 0.65rem;
	}

	.image-12 {
		width: 60px;
		height: 60px;
	}

	.breadcrumbs-list-2 {
		line-height: 1.3rem;
	}

	.inside-page-content {
		margin-top: 260px;
		margin-right: 0%;
		margin-left: 0%;
	}

	.intro-paragraph {
		font-size: 1.125rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.h2-header-link {
		font-size: 1.75rem;
	}

	.inside-page-container.white-background {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.h5-header-link {
		font-size: 1.125rem;
	}

	.qt-data-rows-wrap {
		min-height: auto;
		font-size: 0.875rem;
	}

	.qt-data-row.title-row {
		font-size: 0.9375rem;
	}

	.ordered-list {
		padding-left: 20px;
	}

	.h3-header-link {
		font-size: 1.5rem;
	}

	.block-quote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.h4-header-link {
		font-size: 1.25rem;
	}

	.qt-accordion-item-content-wrap {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.main-content {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.content-button {
		margin-right: 15px;
		margin-left: 15px;
		padding-right: 10px;
		padding-left: 10px;
		font-size: 12px;
	}

	.button-5.tertiary {
		padding-right: 10px;
		padding-left: 10px;
		font-size: 12px;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		padding-left: 2rem;
		font-size: 0.85rem;
	}

	.breadcrumbs-list {
		margin-bottom: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.container-6 {
		max-width: 767px;
	}

	.container-6.inside-page-content-container {
		max-width: 767px;
	}

	.inside-content-wrapper {
		margin-right: 1%;
		margin-left: 1%;
	}

	.inside-content-wrapper.page-title-container {
		max-width: 767px;
	}

	.h1-page-title {
		font-size: 2.25rem;
		line-height: 3rem;
	}

	.inside-page-banner-section {
		display: block;
		height: 347px;
	}

	.page-title-inner-wrap {
		margin-right: 1%;
		margin-left: 1%;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}
}

@media screen and (max-width: 479px) {
	.regular-header {
		font-size: 30px;
		line-height: 30px;
	}

	.menu-button {
		position: relative;
		left: 20px;
		display: block;
		margin-top: 15px;
		margin-right: 25px;
		padding: 18px 0px 0px 20px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: transparent;
	}

	.menu-button.w--open {
		display: block;
		margin-right: 25px;
		padding-top: 18px;
		padding-left: 20px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.image-10 {
		margin-left: auto;
		padding-left: 9px;
	}

	.navbar {
		padding-top: 0px;
		padding-right: 10px;
		padding-left: 10px;
	}

	.navbar.mobile {
		display: block;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.icon-2 {
		display: inline-block;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.menu-button-text {
		display: none;
		font-size: 0.7rem;
	}

	.slider-2 {
		height: 50vh;
		margin-top: 100px;
	}

	.left-arrow {
		margin-top: 50px;
	}

	.text-block-7 {
		padding-right: 60px;
		padding-left: 60px;
	}

	.icon-5 {
		font-family: 'Open Sans', sans-serif;
		font-size: 18px;
		font-weight: 800;
	}

	.right-arrow {
		margin-top: 50px;
	}

	.slider-caption-2 {
		margin-top: 25px;
	}

	.heading-4 {
		padding-top: 0px;
		padding-bottom: 0px;
		font-size: 36px;
		line-height: 30px;
		text-align: center;
	}

	.slide-2 {
		background-position: 50% 40%;
		background-size: 1000px;
	}

	.bold-text-2 {
		padding-right: 0px;
		padding-left: 0px;
		font-size: 12px;
	}

	.image-8.blue {
		margin-left: -10px;
	}

	.inner-block {
		left: 15%;
		top: 15%;
		width: 260px;
		height: 350px;
		margin-top: 0px;
	}

	.inner-block.outline {
		left: 9%;
		top: 19%;
		width: 260px;
		margin-top: 0px;
	}

	.button-underline {
		margin-left: 0px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.button-underline.hover-box {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		margin-bottom: 10px;
	}

	.button-underline.banner {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.button-underline.gdpr {
		margin-top: 0px;
	}

	.home-content-section.video {
		padding: 20px;
	}

	.illustration-block {
		width: 30%;
		height: 500px;
	}

	.heading-cursive {
		font-size: 30px;
		line-height: 26px;
	}

	.heading-cursive.recipes {
		font-size: 32px;
		line-height: 26px;
	}

	.image-block {
		width: 70%;
		height: 500px;
	}

	.text-block-p {
		padding-right: 0px;
		padding-left: 0px;
		font-size: 11px;
	}

	.text-block-p.two {
		font-size: 11px;
		text-align: left;
	}

	.heading {
		font-size: 28px;
		line-height: 28px;
	}

	.heading.blue {
		font-size: 28px;
		line-height: 28px;
	}

	.heading.recipes {
		font-size: 24px;
		line-height: 28px;
	}

	.homepage-slider {
		background-position: 85% 50%, 0px 0px;
		background-size: cover, auto;
		background-repeat: no-repeat, repeat;
	}

	.content-block {
		left: 15%;
		width: 75%;
		top: calc( 72px - 10vw );
	}

	.content-block.two {
		height: auto;
	}

	.content-border {
		left: 10%;
		width: 75%;
	}

	.content-border.two {
		left: 17%;
		top: 17%;
		height: 450px;
	}

	.cursive-header {
		margin-top: 0px;
		font-size: 32px;
		line-height: 30px;
	}

	.cursive-header.slider {
		font-size: 52px;
		line-height: 60px;
	}

	.inner-content-block {
		position: static;
		padding: 20px;
		left: 10%;
	}

	.regular-header {
		font-size: 25px;
		line-height: 23px;
	}

	.regular-header.white {
		margin-bottom: 5px;
		font-size: 24px;
		line-height: 30px;
	}

	.body-paragraph {
		font-size: 12px;
	}

	.icon-block {
		width: 125px;
		height: 125px;
	}

	.icon-block.three {
		height: 90px;
	}

	.color-block {
		padding-right: 15px;
		padding-left: 15px;
	}

	.home-content-blocks {
		padding: 35px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.div-block-31 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.sub-text {
		padding-right: 0px;
		padding-left: 0px;
		font-size: 11px;
	}

	.one-third-column.recipe-1 {
		height: 200px;
	}

	.one-third-column.recipe-3 {
		height: 200px;
	}

	.one-third-column.recipe-2 {
		height: 200px;
	}

	.three-column-section {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.cb-static.footer-cb-4 {
		width: 100%;
	}

	.cb-static.footer-cb-1 {
		width: 100%;
	}

	.cb-static.footer-cb-3 {
		width: 100%;
		padding-top: 1rem;
		padding-right: 0rem;
	}

	.cb-static.footer-cb-left-copy {
		width: 100%;
	}

	.container-4 {
		max-width: none;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container-4.footer-content-container {
		text-align: center;
	}

	.home-content-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.home-content-wrapper.footer-wrapper {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.footer-left-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.footer-right-content-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.social-media-wrapper-2.footer-socmed-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.text-block-p-2 {
		padding-right: 0px;
		padding-left: 0px;
		font-size: 11px;
	}

	.text-block-p-2.two {
		font-size: 11px;
		text-align: center;
	}

	.text-block-p-2.two.footer {
		font-size: 14px;
		text-align: center;
	}

	.home-content-footer {
		background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.5)), to(hsla(0, 0%, 100%, 0.5))), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/background-pattern.jpg');
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.5), hsla(0, 0%, 100%, 0.5)), url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/background-pattern.jpg');
		background-position: 0px 0px, 50% 0%;
		background-size: auto, 2000px;
		background-repeat: repeat, repeat-y;
		background-attachment: scroll, scroll;
	}

	.footer-content {
		display: block;
		padding-right: 32px;
		padding-left: 32px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-column-terms {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-text {
		margin-right: 10px;
		margin-bottom: 10px;
		margin-left: 10px;
		text-align: center;
	}

	.footer-text.sitemap {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.footer-column-rights {
		padding-right: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		text-align: left;
	}

	.video-block-content {
		padding-top: 25px;
	}

	.div-block-39 {
		width: 35px;
		height: 35px;
	}

	.image-9 {
		max-width: 100%;
	}

	.div-block-50 {
		padding-right: 20px;
		padding-bottom: 30px;
		padding-left: 20px;
	}

	.bold-text-3 {
		color: #1f1e1e;
		font-size: 14px;
	}

	.login-link {
		font-weight: 400;
	}

	.open-gdrp-icon {
		margin-right: 0.5rem;
	}

	.cookie-button.cookies {
		margin-right: 0rem;
	}

	.container-2 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.container-2.footer {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.container-2.footer.gdrp {
		padding-top: 3rem;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.container-2.footer.gdrp.top {
		padding: 15px 20px;
	}

	.gdrp-link {
		margin-right: 0rem;
		font-size: 12px;
	}

	.gdrp-section {
		display: none;
	}

	.gdrp-section.top {
		height: 80px;
	}

	.cookie-text-para {
		margin-bottom: 0.5rem;
		padding-right: 0rem;
		padding-left: 0rem;
		font-size: 0.612rem;
		line-height: 1.075rem;
	}

	.cookie-text-para.top {
		padding-right: 10px;
		padding-left: 10px;
		font-size: 10px;
		text-align: left;
	}

	.exit-icon-div {
		width: 20px;
		height: 20px;
	}

	.cookie-div {
		width: 100%;
		min-width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-signoff-link {
		margin-right: 0.25rem;
		margin-bottom: 10px;
		margin-left: 0.25rem;
	}

	.search-section {
		margin-right: 6px;
		margin-bottom: 0px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.search {
		width: auto;
	}

	.search-input {
		top: auto;
		right: auto;
	}

	.footer-signoff-back-top-button {
		margin: 1em 1rem;
	}

	.menu-button-2 {
		position: relative;
		top: 0%;
		bottom: 15px;
		width: 54px;
		height: 54px;
		padding: 8px 6px;
	}

	.menu-button-text-2 {
		display: none;
		font-size: 0.7rem;
	}

	.mobile-menu-arrow {
		width: 17%;
	}

	.main-nav-link.mobile-duplicate {
		width: 83%;
	}

	.mobile-menu-arrow-2 {
		width: 17%;
	}

	.main-nav-link-2.mobile-duplicate {
		width: 83%;
	}

	.nav-menu {
		margin-top: 105px;
	}

	.mobile-menu-arrow-3 {
		width: 17%;
	}

	.container {
		max-width: none;
	}

	.container.nav-container {
		max-width: none;
	}

	.container.brand-container {
		margin-left: 0px;
	}

	.brand-2 {
		width: 200px;
		height: 65px;
		margin-top: 0.75rem;
		margin-right: 0.5rem;
		margin-bottom: 0.75rem;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
	}

	.brand-2.w--current {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		margin-left: auto;
		float: none;
	}

	.logo {
		display: block;
		width: 225px;
		margin-right: auto;
		margin-left: auto;
	}

	.container-logo {
		display: block;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.image-12 {
		width: 35px;
		height: 35px;
	}

	.mobile-menu-block {
		margin-left: 0px;
		padding-left: 0px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.search-2 {
		width: auto;
	}

	.search-2.mobile {
		margin-right: -20px;
		margin-left: 0px;
		padding-right: 0px;
	}

	.search-section-2 {
		margin-right: 6px;
		margin-bottom: 0px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.search-3 {
		width: auto;
	}

	.search-input-2 {
		top: auto;
		right: auto;
	}

	.breadcrumbs-list-2 {
		line-height: 1.3rem;
	}

	.inside-page-content {
		margin-top: 260px;
	}

	.intro-paragraph {
		line-height: 1.5rem;
	}

	.inside-page-container.white-background {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.leftnavigation {
		margin-top: -100px;
		margin-bottom: 0px;
		padding-top: 120px;
		padding-bottom: 0px;
	}

	.main-content {
		padding-top: 0.75rem;
	}

	.secondary-button {
		margin-top: 20px;
		margin-bottom: 20px;
		margin-left: 0px;
		font-size: 12px;
		line-height: 17px;
	}

	.primary-button {
		font-size: 12px;
		line-height: 17px;
	}

	.intro-paragraph-2 {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
		padding-left: 1rem;
	}

	.button-styling {
		padding-left: 0px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.secondary-button-style {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.container-6 {
		max-width: 479px;
	}

	.container-6.inside-page-content-container {
		max-width: none;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.inside-content-wrapper {
		margin-right: 0%;
		margin-left: 0%;
	}

	.inside-content-wrapper.page-title-container {
		padding-right: 0%;
		padding-left: 0%;
	}

	.h1-page-title {
		font-size: 1.95rem;
		line-height: 2.75rem;
		letter-spacing: 0rem;
		text-transform: none;
	}

	.page-title-inner-wrap {
		margin-right: 0%;
		margin-left: 0%;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.image-background,
	.pattern-block {
		height: calc(1060px - 125vw);
	}

	.button-link.two {
		margin-top: 0;
	}

	.paragraph-2 {
		padding: 0;
		font-size: 12px;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.page-container {
	width: 100%;
	overflow: hidden;
}

.cookie-text-para * {
	color: #fff;
}

.cookie-text-para p {
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	color: inherit;
}

.cookie-text-para p:last-child {
	margin-bottom: 0px;
}

.cookie-text-para a {
	color: #fff;
	text-decoration: underline;
}

.cookie-text-para a:hover {
	color: #d4d1d1;
}

.div-block-58 button {
	background-color: transparent;
	margin: 0px;
}

.header-container {
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 5;
}

.mobile-form-container {
	display: none;
}

.menu-button-text {
	text-transform: uppercase;
}

.w-nav-overlay-webflow {
	left: auto;
	z-index: 1;
	top: 0;
	width: auto;
	position: absolute;
	overflow: hidden;
	display: none;
	top: 100%;
}

.sronly {
	min-width: auto !important;
}

.search-form-desktop {
	margin: 0px;
	padding: 0px;
	display: inline-block;
	width: 200px;
}

.search-form-mobile {
	display: inline;
}

.brand {
	display: block;
	width: 138px;
	margin-right: 20px !important;
}


.brand.cydna-logo {
	width: 85px;
	padding-top: 0rem;
}

.container-logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.image-10 {
	padding: 8px;
}

.search-button-2.mobile {
	display: none;
}

@media (max-width: 991px) {
	.image-10 {
		padding: 8px;
		margin-top: 0px;
		max-width: 95%;
	}

	.mobile-form-container {
		display: flex;
		align-items: center;
		justify-content: center;
		align-content: center;
		margin-top: 15px;
		margin-right: 20px;
	}

	.search-2.mobile {
		margin-top: 0px;
		padding-right: 0px;
	}

	.search-button-2 {
		margin-top: 5px;
	}

	.brand {
		width: 132px;
		margin-right: 0 !important;
	}

	.brand.cydna-logo {
		width: 100px;
	}

	.brand.cydna-logo img {
		padding: 9px;
	}

	.container-logo {
		display: inline-flex;
		float: left;
	}

	.mobile-menu-block {
		display: inline-flex;
		float: right;
	}

	.navbar.mobile {
		padding-left: 20px;
	}
}

@media screen and (max-width: 767px) {
	.brand {
		width: 125px;
		margin-top: 0px;
		margin-right: 0px !important;
		padding-left: 0px;
	}

	.brand.cydna-logo {
		width: 90px;
	}

	.brand-image {
		margin-top: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.navbar.mobile {
		padding-left: 10px;
	}

	.search-input {
		width: 107px !important;
	}

	.menu-button {
		margin-right: 20px;
	}
}

@media screen and (max-width: 479px) {
	.mobile-form-container.tablet {
		display: none;
	}

	.search-button-2.mobile {
		margin-top: 18px;
		display: inline-block;
		margin-right: -20px;
	}

	.search-2.mobile {
		margin-right: 0px;
	}

	.mobile-form-container {
		margin-right: -20px;
	}

	.brand {
		width: 105px;
		margin-right: 0 !important;
		padding-left: 0px;
	}

	.brand.cydna-logo {
		width: 80px;
	}

	.container-logo {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
}

@media screen and (max-width: 380px) {
	.search-input {
		width: 100px !important;
	}
}

/****************** Accesible menu **********************/
/*****************************************************/
.container.nav-container {
	margin-bottom: 0px;
	padding: 0px;
}

.main-nav-link-2 {
	padding: 0px;
}

.main-nav-link-2 a.nav-drop-toggle-link {
	padding: 0rem 30px 20px;
	display: inline-block;
	color: #163375;
	text-decoration: none;
}

.main-nav-link-2 a.nav-drop-toggle-link.lowercase {
	text-transform: capitalize;
}

.contact-block,
.contact-block:hover {
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.container.nav-container li.main-nav-link-2 .dropdown-container {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.container.nav-container li.main-nav-link-2 .dropdown-container ul {
	display: none;
	padding: 0.25rem 0.5rem 0.5rem;
	background-color: #163375;
	margin: 0px;
	list-style-type: none;
	min-width: 200px;
}

.container.nav-container li.main-nav-link-2 .dropdown-container ul a {
	padding: 10px 0.5rem;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	font-size: 12px;
	line-height: 1.3rem;
	font-weight: 600;
	text-decoration: none;
	display: inline-block;
	width: 100%;
	text-transform: none;
}

.container.nav-container li.main-nav-link-2 .dropdown-container ul a:hover,
.container.nav-container li.main-nav-link-2 .dropdown-container ul a.w--current {
	background-color: #fff;
	color: #163375;
	font-weight: 400;
	display: inline-block;
}

.container.nav-container li.main-nav-link-2.open .dropdown-container {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.container.nav-container li.main-nav-link-2:last-child .dropdown-container {
	left: -125px;
}

.container.nav-container li.main-nav-link-2.open .dropdown-container ul {
	display: inline-block;
}

@media screen and (max-width: 1055px) {
	.container.nav-container li.main-nav-link-2 {
		margin: 0px;
	}

	.main-nav-link-2 a.nav-drop-toggle-link {
		padding-left: 28px;
		padding-right: 28px;
	}
}



/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background-color: #163375;
}

.mobile-navigation-menu li a.nav-a,
.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: #fff;
	line-height: 1rem;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	text-transform: uppercase;
	text-align: left;
}

.mobile-navigation-menu li a.nav-a:hover,
.mobile-navigation-menu li.active a.nav-a {
	background-color: #fff;
	color: #213a7f;
	font-weight: 600;
}

.mobile-navigation-menu li a.nav-a.lowercase {
	text-transform: capitalize;
}

.mm-listitem:after,
.mm-navbar {
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
}

.mm-listitem .mm-btn_next:before,
.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before,
.mm-navbars_top {
	border-color: hsla(0, 0%, 100%, 0.25);
}

.mm-listitem > a,
.mm-listitem > span {
	padding: 15px 10px 15px 20px;
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #b1bbd1;
}

.mm-listitem:after {
	left: 0;
}

.mm-navbar {
	height: 45px;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -15px;
}

.mm-btn_prev:before {
	left: 18px;
	top: 4px;
}

.mm-navbar__title {
	padding-top: 14px;
}

.mm-btn_next:hover {
	background-color: #4a73bc;
}

.social-media-link.header.mobile {
	flex-grow: 0 !important;
	flex-shrink: 0 !important;
	flex-basis: 32px !important;
}

.mm-menu_navbar_top-1 .mm-panels {
	top: 55px;
}

.mm-navbars_top {
	padding: 11px 12px 0px 12px;
	height: 55px;
}

.language.language-mobile {
	text-align: right !important;
}

.language.language-mobile img {
	min-width: 20px;
	width: 20px;
	height: 15px;
}

.language-block {
	padding-right: 0px;
	margin-right: 0px;
}

.mobile-navigation-menu .language.language-mobile {
	flex-grow: 0;
	flex-grow: 0 !important;
	flex-shrink: 0 !important;
	min-width: 20px;
	margin-left: 15px;
}

.mobile-navigation-menu .language.language-mobile.first {
	flex-grow: 1 !important;
}

.mm-navbars_top {
	padding: 11px 5px 0px 5px;
}

@media (max-width: 400px) {
	.social-media-link.header.mobile {
		margin-right: 0.3rem;
	}

	.mobile-navigation-menu .language.language-mobile {
		margin-left: 5px;
	}
}


/********************* Homepage *********************/
/*****************************************************/
.body-paragraph p,
.body-paragraph li,
.body-paragraph span,
.body-paragraph a {
	font-family: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit;
	font-size: inherit !important;
}

.text-block-p p,
.text-block-p li,
.text-block-p span,
.text-block-p a {
	font-family: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit !important;
	font-size: inherit !important;
}

.sub-text p,
.sub-text li,
.sub-text span,
.sub-text a {
	font-family: inherit;
	line-height: inherit;
	font-weight: inherit;
	color: inherit !important;
	font-size: inherit !important;
}

.paragraph-2 p,
.paragraph-2 li,
.paragraph-2 span,
.paragraph-2 a {
	font-family: inherit;
	line-height: inherit;
	font-weight: bold !important;
	color: inherit !important;
	font-size: inherit !important;
}

.home-title-link {
	border-bottom: 0px;
	text-decoration: none;
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-link {
	position: relative;
	width: 100%;
	height: 100%;
	display: inline-block;
}

.w-slide {
	background-position: 100% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.sub-text.slider p,
.sub-text.slider li,
.sub-text.slider span,
.sub-text.slider a,
.slider-inner p,
.slider-inner li,
.slider-inner span,
.slider-inner a {
	font-size: 14px;
	line-height: inherit;
	font-weight: bold;
	color: #1f1e1e !important;
	font-size: inherit !important;
}

.sub-text.slider {
	margin-top: 15px;
}

.sub-text.slider-inner {
	display: inline-block;
	width: auto;
	font-size: 17px;
	padding: 10px 30px;
}

.sub-text.slider-inner p:last-child {
	margin-bottom: 0px;
}

@media (max-width: 479px) {
	.sub-text.slider {
		padding-right: 0;
		padding-left: 0;
	}

	.sub-text.slider-inner {
		padding: 0;
		line-height: 1.5;
	}
}


/****************** Inside Page ******************/
/*************************************************/
@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.inside-page-container.with-left-sidebar .left-nav-wrapper {
	max-width: 307px;
}

.inside-page-container.both-sidebars .left-nav-wrapper {
	max-width: 256px;
}

.inside-page-container.both-sidebars .right-nav {
	max-width: 253px;
}

.inside-page-container.with-right-sidebar .right-nav {
	max-width: 309px;
}

.inside-page-container.with-right-sidebar .content-container.threequarterwidth {
	width: calc(100% - 21%);
}

.inside-page-container.with-left-sidebar .content-container.threequarterwidth {
	width: calc(100% - 256px - 2rem);
}

.inside-page-container.both-sidebars .content-container.threequarterwidth {
	width: calc(100% - 21% - 256px - 2rem);
}

.breadcrumbs-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumbs-list li:last-child a {
	color: #238425;
	font-weight: 700;
	opacity: 1;
}

.breadcrumbs-list li:last-child a:hover {
	color: #4a73bc;
}

.breadcrumb-divider {
	line-height: 1rem;
}

.left-nav-links ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.left-nav-links ul li {
	margin: 0px;
	padding: 0px;
}

.left-nav-links ul li a.active {
	border-left: 4px solid #2db642;
	color: #2db642;
	border-right: 1px solid rgba(51, 51, 51, 0.2);
}

.inside-page-container.white-background {
	min-height: 500px;
}

@media (max-width: 991px) {
	.inside-page-container.with-right-sidebar .content-container.threequarterwidth,
	.inside-page-container.both-sidebars .content-container.threequarterwidth,
	.inside-page-container.with-left-sidebar .content-container.threequarterwidth {
		width: 100%;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-nav .box {
	margin-bottom: 2.441em;
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.right-nav .box h4,
.right-nav .box h3 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: 'Open Sans', sans-serif;
	color: #238425;
	font-style: normal;
	font-weight: 800;
	font-size: 18px;
	line-height: 24px;
}

.right-nav .box a {
	border-style: solid solid none;
	border-width: 1px;
	border-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
	font-size: 1rem;
	text-decoration: none;
	border-style: none none solid;
	border-width: 0px 0px 2px;
	transition: all 200ms ease;
	border-color: transparent transparent rgba(51, 51, 51, 0.2);
}

.right-nav .box a:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #2db642;
}

.right-nav .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-nav .box .contentbox_item_image {
	width: 24px;
}

.right-nav .box.documents td:first-child {
	padding-right: 7px;
}

.right-nav .box.tools td:first-child img {
	margin-right: 5px;
}

.right-nav table td img {
	max-width: none;
}

@media (max-width: 991px) {
	.inside-page-container {
		display: block;
	}

	.right-nav {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px solid #ccc;
	}

	.right-nav,
	.right-nav .box {
		width: 100%;
		max-width: none;
		padding-left: 0px;
		padding-right: 0px;
		margin-right: 0px;
		margin-left: 0px;
	}
}

/********************* Footer **********************/
/*****************************************************/
.phone-link.desktop {
	display: inline;
}

.phone-link.mobile {
	display: none;
	background-color: transparent;
	text-decoration: none;
	color: #333;
}

.phone-link.mobile:hover {
	text-decoration: none;
	color: #333;
}

#GRIPFooterLogo {
	line-height: 11px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-top: 0px !important;
	opacity: 0.6;
	transition: all 0.3s ease;
}

#GRIPFooterLogo:hover {
	opacity: 1;
}

.quick-links {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}

.quick-links li {
	margin: 0px;
	padding: 0px;
}

.footer-link.active {
	color: #4a73bc;
}

@media (max-width: 991px) {
	.phone-link.mobile {
		display: inline;
	}

	.phone-link.desktop {
		display: none;
	}
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.content-container a,
.homeboxes a,
#cookie-consent-form-container form a {
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
	font-size: 1rem;
	line-height: 1.3rem;
	text-decoration: none;
	border-style: none none solid;
	border-width: 0px 0px 2px;
	border-color: transparent transparent rgba(51, 51, 51, 0.2);
}

.content-container a:hover,
.homeboxes a:hover,
#cookie-consent-form-container form a:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	background-color: transparent;
	color: #2db642;
}

.inside-page-container h1 a,
.homeboxes h1 a {
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
	font-size: inherit;
	line-height: 40px;
	font-weight: inherit;
	text-decoration: none;
}

.inside-page-container h2 a,
.homeboxes h2 a {
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	text-decoration: none;
}

.inside-page-container h3 a,
.homeboxes h3 a {
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	text-decoration: none;
}

.inside-page-container h4 a,
.homeboxes h4 a {
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	text-decoration: none;
}

.inside-page-container h5 a,
.homeboxes h5 a {
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	text-decoration: none;
	text-transform: uppercase;
}

.inside-page-container h6 a,
.homeboxes h6 a {
	border-bottom: 2px solid rgba(51, 51, 51, 0.2);
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	text-decoration: none;
	text-transform: uppercase;
}

.inside-page-container h1 a:hover,
.inside-page-container h2 a:hover,
.inside-page-container h3 a:hover,
.inside-page-container h4 a:hover,
.inside-page-container h5 a:hover,
.inside-page-container h6 a:hover,
.homeboxes h1 a:hover,
.homeboxes h2 a:hover,
.homeboxes h3 a:hover,
.homeboxes h4 a:hover,
.homeboxes h5 a:hover,
.homeboxes h6 a:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #2db642;
}

h1 {
	margin-top: 0px;
	font-family: 'Open Sans', sans-serif;
	font-size: 1.8rem;
	line-height: 2rem;
	font-weight: 700;
	color: #196b19;
	text-transform: none;
}

@media screen and (max-width: 991px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}
}

h2 {
	font-size: 1.6rem;
	line-height: 1.8rem;
	font-weight: 600;
}

@media screen and (max-width: 767px) {
	h2 {
		font-size: 1.6rem;
		line-height: 2rem;
	}
}

@media screen and (max-width: 479px) {
	h2 {
		font-size: 1.5rem;
		line-height: 1.8rem;
	}
}

h3 {
	font-size: 1.4rem;
	line-height: 1.6rem;
	font-weight: 600;
}

h4 {
	font-size: 1.2rem;
	line-height: 1.6rem;
	font-weight: 600;
}

h5 {
	font-size: 1rem;
	line-height: 1.4rem;
	font-weight: 800;
}

h6 {
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 800;
}

h2,
h3,
h4,
h5,
h6 {
	font-family: 'Open Sans', sans-serif;
	color: #196b19;
}

h5,
h6 {
	text-transform: uppercase;
}


p {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	text-decoration: none;
}

/* buttons */
.inside-page-container .button,
.inside-page-container a.primary,
.homeboxes a.primary,
.primary,
#jobs_module.cms_form .cms_form_button,
.inside-page-container button[type="submit"],
.inside-page-container .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary {
	font-size: 13px;
	line-height: 13px;
	text-decoration: none;
	text-transform: uppercase;
	transition: transform 400ms ease, -webkit-transform 400ms ease;
	color: #238425;
	font-weight: 800;
	border-bottom: 1px solid transparent;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/Underline-blue-04.png');
	background-position: center 10px;
	background-size: 120px;
	background-repeat: no-repeat;
	padding: 10px 10px 13px 10px !important;
	min-width: 120px;
	text-align: center;
	display: inline-block;
	border: 0px;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
}

.primary:hover,
.inside-page-container a.primary:hover .inside-page-container button[type="submit"]:hover,
.inside-page-container .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.homeboxes a.primary:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.secondary,
.inside-page-container a.secondary,
.homeboxes a.secondary,
.inside-page-container button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-page-container .cms_form_button.secondary,
.inside-page-container .button.secondary {
	padding: 10px 20px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(51, 51, 51, 0.2);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #238425;
	font-size: 13px;
	line-height: 20px;
	font-weight: 800;
	letter-spacing: 0px;
	margin-left: 5px;
	margin-right: 5px;
	text-transform: uppercase;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
}

.secondary:hover,
.inside-page-container a.secondary:hover,
.inside-page-container button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-page-container .cms_form_button.secondary:hover,
.inside-page-container .button.secondary:hover,
.homeboxes a.secondary:hover {
	border-color: #238425;
	background-color: #238425;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	color: #fff;
}

.tertiary,
.inside-page-container button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-page-container a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 10px 20px;
	border-style: solid;
	border-width: 0.5px;
	border-color: rgba(51, 51, 51, 0.2);
	border-radius: 0px;
	background-color: #fff;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	font-family: 'Open Sans', sans-serif;
	color: #238425;
	font-size: 13px;
	line-height: 20px;
	font-weight: 800;
	letter-spacing: 0px;
	margin-left: 5px;
	margin-right: 5px;
	text-transform: uppercase;
	-webkit-appearance: none;
	-webkit-border-radius: 0;
}

.tertiary:hover,
.inside-page-container a.tertiary:hover,
.inside-page-container button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	border-color: #238425;
	background-color: #238425;
	box-shadow: 0 12px 9px -5px rgba(0, 0, 0, 0.26);
	color: #fff;
}

/* messages */
#message.success {
	background-color: #98e4a3;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

#message.error {
	background-color: #f3baba;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
	width: 100%;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #196b19;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #238425;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #dddddd;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-page-container input[type="text"],
.inside-page-container input[type="tel"],
.inside-page-container input[type="password"],
.inside-page-container input[type="email"],
.inside-page-container select,
.inside-page-container textarea,
.inside-page-container input[type="search"] {
	max-width: 100%;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
}

.inside-page-container textarea {
	resize: vertical;
}

.inside-page-container label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-page-container input[type="text"],
	.inside-page-container input[type="tel"],
	.inside-page-container input[type="password"],
	.inside-page-container input[type="email"],
	.inside-page-container select,
	.inside-page-container textarea,
	.inside-page-container input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #2db642;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #444;
	font-size: 1.25rem;
	line-height: 1.75rem;
	font-weight: 400;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}

/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
}


/****************** Cntent Modules ******************/
/***************************************************/
/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#services_module.list .list_item .footer,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: #c9c6c4 !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top: 1px solid #c9c6c4;
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid #c9c6c4;
}

/* dates */
#alerts_module.list .title .date,
#events_module.cms_list .cms_date,
#feeds_module.cms_list .cms_list_item .cms_date h4,
#feeds_module.cms_entity .cms_date h4,
#jobs_module.cms_list .cms_list_item .cms_date h3,
#news_module.cms_list article time,
#news_module.cms_entity .cms_date h3,
#photoalbums_module.cms_list .cms_list_item .cms_number_of_images, /* this is not a date but styled as one */
.posts-node .posts-field.posts-field-posted p,
#video_module.cms_list .cms_list_item .cms_date,
#video_module.cms_entity .cms_date h3,
#reservations_module.entity .metadata2 {
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 500;
	color: #2e2b2b;
}

/* ui form tags */
.ui-form-error {
	display: none;
}

.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: none;
	margin-top: 1rem !important;
	margin-bottom: 0.5rem !important;
	color: #213a7f !important;
	font-size: 1.2rem !important;
	line-height: 1.5rem;
	font-weight: 500 !important;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #cc0000 !important;
	font-style: italic;
	font-family: 'Roboto Condensed', Arial, sans-serif;
}

.required_information {
	font-family: 'Roboto Condensed', Arial, sans-serif;
	color: #cc0000;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	font-size: 0.875rem;
	line-height: 1.42857143;
	color: #333333;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #cccccc;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-note {
	line-height: 1rem;
	margin-top: 5px;
}

.pagination-wrapper > * {
	line-height: 1.2;
}

@media (max-width: 991px) {
	.ui-form-fields {
		padding: 0px;
	}

	.ui-form-label {
		position: static;
		padding: 0px;
		height: auto;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-input {
		width: 100%;
	}

	.ui-form-input input[type="text"],
	.ui-form-input input[type="tel"],
	.ui-form-input input[type="password"],
	.ui-form-input input[type="email"],
	.ui-form-input input[type="file"],
	.ui-form-input select,
	.ui-form-input textarea,
	.ui-form-fields .select2-container,
	.ui-form-fields .select2-container-multi,
	.ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}

	.ui-form-panel {
		padding: 0 !important;
	}

	.ui-form-buttons {
		text-align: left;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
		width: auto;
	}
}

/* pagination */
.pagination-wrapper .pagination-row td span[style="color:#999999;font-weight:bold;"] {
	color: #757575 !important;
	cursor: not-allowed;
}


/*accounts*/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 365px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #c9c6c4;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #213a7f;
	font-size: 1.714rem;
	line-height: 2.414rem;
	font-weight: 600;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #cc0000;
	font-style: italic;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 767px) {
	#account_addresses .address_section td {
		display: block;
		width: 100%;
	}

	#account_addresses .address_section td br {
		display: none;
	}

	#account_addresses .address_section td a + br {
		display: block;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* events */
.fc-day-grid-event .fc-time {
	font-weight: 700;
}

#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .location-label-events-module {
	font-weight: 500;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
	font-size: 1rem;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	padding-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .event-category-label {
	margin-bottom: 10px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0;
}

#faqs_module.cms_list .cms_list_item .cms_title h2 a,
#faqs_module.cms_list .cms_list_subitem .cms_title h2 a {
	border-bottom: 0px !important;
}

#faqs_module .cms_list_item > ul {
	margin: 0;
	list-style-type: disc;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding: 0 0 10px;
	list-style-type: none;
}


/* jobs */
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: #c9c6c4;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 7px 1.15rem 8px;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/* locations */
#locations_module.list .location_types_title {
	border-color: #c9c6c4;
}

#locations_module_display_view_contentbox {
	text-align: left;
}

#locations_module.entity {
	overflow: hidden;
}

/* news */
.box.archive {
	overflow: hidden;
}

#news_module.cms_list article {
	padding-bottom: 1px;
}

#news_module.cms_list .cms_categories_section .cms_category_item .image {
	margin-bottom: 10px;
}

#news_module.cms_list .cms_metadata1.cms_title h3 {
	margin-top: 0;
}

#news_module.cms_list article h2 {
	padding-top: 0;
}

.news_module.cms_category_list.header {
	margin-bottom: 20px;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0;
}

#news_module.cms_entity .quaternary_title {
	margin-top: 0;
}

.archive_links {
	overflow: hidden;
	margin-bottom: 0.75rem;
}

.archive_links a,
.archive_links a.selected {
	padding-bottom: 0;
}

@media (max-width: 991px) {
	#news_module.cms_entity .cms_content .image {
		float: none !important;
		margin-left: 0;
		height: auto !important;
	}

	#news_module.cms_list .cms_categories_row {
		margin-bottom: 20px;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_list_item table td.image a {
	border-bottom-width: 0;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* Photo Albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_list_item .cms_title h3 {
	padding-top: 0;
}

#photoalbums_module.cms_entity .js-with-lightbox-magnifier {
	overflow: hidden;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

.slideshow-wrapper #main-slideshow .slide-caption {
	opacity: 1;
	background-color: #000c;
	font-size: inherit;
}

/* Products (not e-commerce) */
#products_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#products_module.cms_entity .cms_content .image {
	margin-right: 15px;
	margin-left: 0px;
}

@media (max-width: 991px) {
	#products_module.cms_list .cms_list_item.left,
	#products_module.cms_list .cms_list_item.right {
		width: 100%;
		display: block;
		padding-left: 0px;
		padding-right: 0px;
	}

	#products_module.cms_list .cms_list_item .tile_description .image {
		float: none;
		display: block;
		padding-left: 0px;
	}

	#products_module.cms_list .cms_list_item .cms_title {
		padding: 0px;
	}

	#products_module.cms_list .cms_list_item {
		padding-bottom: 1.5rem !important;
	}

	#products_module.cms_list .cms_item_divider {
		margin-top: 0px !important;
	}
}

/* resources */
#moduleDocuments_module.cms_list .cms_items .cms_item .cms_item_description {
	margin-top: 5px;
}

.moduleDocuments-rss-feed-link {
	border-bottom-width: 0 !important;
}

.box.tools a[href^="?tag"] {
	border-bottom-width: 0;
}

/* submission forms */
#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

#submissionforms_module.cms_form .cms_divider {
	display: none;
}

#submissionforms_module.cms_form .cms_required em {
	display: none;
}

#submissionforms_module.cms_form .required_information em {
	font-family: 'Roboto Condensed', Arial, sans-serif;
	color: #cc0000;
}

#submissionforms_module.cms_form .sigPad .sigNav a {
	font-size: 11px;
}

#submissionforms_module.cms_form tr[data-required="true"] .cms_label label:after,
#submissionforms_module.cms_form tr[data-required="true"] .cms_label div:after {
	content: "*";
	color: #cc0000;
	padding-left: 5px;
	font-style: italic;
	font-size: 1.2rem;
	font-family: 'Roboto Condensed', Arial, sans-serif;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 0px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	border-bottom: 0 none;
}

#submissionforms_module.cms_form .sigWrapper.current {
	height: 102px;
	width: 100%;
	max-width: 302px;
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input {
	display: block;
}

#submissionforms_module.cms_form .cms_field,
#submissionforms_module.cms_form .cms_required,
#submissionforms_module.cms_form .cms_label {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .formSection {
	margin-bottom: 15px;
}

#submissionforms_module.cms_form .cms_field .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	border: 0 none;
	border-top: 1px solid #c9c6c4;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 40%;
	transform: translate(0px, -60%);
}

#submissionforms_module .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 300;
	color: #2e2b2b;
}

#submissionforms_module .ui-form-label span {
	color: #2e2b2b;
	font-weight: 300;
}

#submissionforms_module .ui-form-label em {
	color: #cc0000;
	font-style: italic;
	font-family: 'Roboto Condensed', Arial, sans-serif;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 26%;
}

.module-forms.confirmation .step-title {
	font-family: 'Roboto Condensed', Raleway, sans-serif;
	color: #213a7f;
	font-size: 1.714rem;
	line-height: 2.414rem;
	font-weight: 600;
	margin-top: 1.5rem;
	margin-bottom: 0.7rem;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_table_fullwidth,
#submissionforms_module.homepage_contentbox .cms_table_fullwidth,
#submissionforms_module.cms_form .cms_field textarea[cols="60"],
#submissionforms_module.cms_form .cms_field input.hasDatepicker[size="60"][type="text"] {
	width: auto;
}

#submissionforms_module.cms_form .cms_buttons {
	display: flex;
	align-items: center;
	margin-top: 10px;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_required,
	#submissionforms_module.cms_form .cms_label {
		padding-bottom: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label::after {
		content: "";
		padding-left: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label label::after,
	#submissionforms_module tr[data-required="true"] .cms_label div::after {
		content: "*";
		color: #cc0000;
		padding-left: 5px;
		font-family: 'Roboto Condensed', Arial, sans-serif;
	}

	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 37%;
		left: 4px;
	}

	#submissionforms_module.cms_form .cms_table_fullwidth,
	#submissionforms_module.homepage_contentbox .cms_table_fullwidth,
	#submissionforms_module.cms_form .cms_field textarea[cols="60"],
	#submissionforms_module.cms_form .cms_field input.hasDatepicker[size="60"][type="text"] {
		width: 100%;
	}
}

/* videos */
#video_module.cms_list .cms_video {
	padding-right: 20px;
}

#video_module.cms_entity .cms_metadata1.cms_date h3 {
	margin-top: 15px;
}

#video_module.cms_list .cms_metadata1.cms_title {
	padding-top: 0;
}

#video_module.cms_list .cms_list_item {
	padding-bottom: 1.5em;
	margin-bottom: 1.5em;
	border-bottom: 1px solid #c9c6c4;
}

#video_module.cms_list .cms_list_item:last-of-type {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: 0 none;
}

.sidebar-section-wrap.box.categories .content a {
	display: inline-block;
	margin-bottom: 0.875rem;
}

/* search */
.collection.search-collection > .node.search-node {
	border-top: 1px solid #c9c6c4;
	margin: 1.5em 0 0 0;
	padding: 1.5em 0 0 0;
}

.collection.search-collection > .node.search-node:first-of-type {
	border-top: 0 none;
	padding: 0;
}

.collection.search-collection .search-field-title {
	line-height: 1.6rem;
}

input[type="search"] {
	border: 1px solid #ccc;
}

/*eCommerce*/
#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3 {
	font-size: 1.375rem;
	margin-bottom: 10px;
}

#products_module.responsive a {
	border-bottom: 1px solid #797676;
}

#products_module.responsive a:hover {
	border-bottom: 1px solid transparent;
}

#products_module.responsive .item-image a,
#products_module.responsive .paginationView {
	border-bottom: 0 none;
}

#products_module.responsive .item-image a:hover,
#products_module.responsive .paginationView:hover {
	border-bottom: 0 none;
}

#products_module.responsive .item-old-price {
	color: #a3a3a3;
	font-weight: 300;
}

#products_module.responsive .item-sale-price {
	color: #cc0000 !important;
	font-weight: 700;
}

#products_module.responsive .item-sale-price .sale-price-label {
	display: none;
}

#products_module.responsive .category-header-column,
#products_module.responsive .category-header-column {
	width: 100%;
}

#products_module.responsive .item-old-price {
	color: #757575;
	font-weight: 300;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #757575;
	cursor: not-allowed;
}

.product-details-info #product-container label {
	display: block;
	margin-top: 10px;
	margin-bottom: 3px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding: 15px;
}

.product-details-info #product-container br {
	display: none;
}

#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
	width: 25%;
}

#products_module.responsive .add_to_cart_form .contentbox_item {
	margin-top: 15px;
}

#products_module.responsive .contentbox_item .accepted-formats {
	word-wrap: break-word;
}

#products_module.responsive .viewMoreAttributeOptions,
#products_module.responsive .hideMoreAttributeOptions {
	width: auto;
}

#products_module.responsive .affiliate_box .affiliate_url {
	margin-bottom: 3px;
	display: inline-block;
	width: auto;
}

#products_module.responsive .availability_box {
	margin-bottom: 20px;
}

#products_module.responsive .availability_box .available,
#products_module.responsive .availability_box .unavailable {
	display: block;
}

#products_module.responsive .availability_box .available {
	color: #2f6b3a;
	font-weight: 500;
}

#products_module.responsive .availability_box .unavailable {
	color: #163a59;
	font-weight: 300;
}

#products_module.responsive label em,
#products_module.responsive .ui-form-label em {
	color: #cc0000;
	font-style: italic;
	font-family: 'Roboto Condensed', Arial, sans-serif;
}

#products_module.responsive .required-fields-information em {
	color: #cc0000;
	font-style: italic;
	font-family: 'Roboto Condensed', Arial, sans-serif;
}

#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: 300;
}

#products_module.responsive .tab-item-content .product-review {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 1.5em;
	padding-bottom: 1.5em;
	border-bottom: 1px solid #c9c6c4;
}

#products_module.responsive .tab-item-content .product-review:last-of-type {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: 0 none;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer {
	margin-left: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-have-a-question {
	border-top: 1px solid #c9c6c4;
}

#products_module.responsive .add_to_cart_form .cart-estimate-button {
	width: 100%;
}

#products_module.responsive.cms_entity .tab-item-content .separator {
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid #c9c6c4;
}

#products_module.responsive.cms_entity .related-items .related-items-title,
#products_module.responsive.cms_entity .recently-viewed-items .recently-viewed-items-title {
	color: #213a7f;
	font-size: 1.7rem;
	line-height: 2.3rem;
	font-weight: 600;
}

@media (min-width: 481px) and (max-width: 720px) {
	#products_module.responsive .add_to_cart_form .cart-estimate-button {
		width: 47%;
		margin-bottom: 5px;
		margin-right: 2%;
	}
}

@media (max-width: 720px) {
	#products_module.responsive.cms_form .ui-form-field {
		width: 100% !important;
	}

	#products_module.responsive.cms_form .ui-form-buttons {
		text-align: left;
	}
}

@media (max-width: 767px) {
	#account_module.entity #account_orderhistory table td {
		display: block;
		width: 100%;
		padding-bottom: 15px;
		text-align: left !important;
	}
}

#s-customer {
	overflow: hidden;
}

#s-customer #s-sign-in .ui-form-label {
	height: auto;
	padding-bottom: 0;
}

#s-customer #s-sign-in .ui-form-label em {
	color: #cc0000;
	font-style: italic;
	font-family: 'Roboto Condensed', Arial, sans-serif;
}

#s-checkout .ui-form-label label em,
.ui-form-field-subtitle .ui-form-label span em {
	color: #cc0000;
	font-style: italic;
	font-family: 'Roboto Condensed', Arial, sans-serif;
}

#products_module.responsive.cms_form .required-fields-information em {
	font-family: 'Roboto Condensed', Arial, sans-serif;
}

.ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: normal;
	color: #363a3e;
}

#s-cart .w-col.s-summary #s-estimate-shipping button {
	margin: 0;
	white-space: normal;
}

#s-checkout fieldset {
	border: 0 none;
}

#s-order-confirmation {
	overflow: hidden;
}

@media (max-width: 991px) {
	#s-cart #s-promotions.s-expanded .s-form-container .s-fields.s-label,
	#s-cart #s-promotions.s-expanded .s-form-container .s-fields.s-form {
		float: none;
	}
}

@media (min-width: 767px) {
	#s-checkout fieldset .ui-form-text > input#ShippingPhone[type="text"],
	#s-checkout fieldset .ui-form-text > input#BillingPhone[type="text"] {
		width: 189px;
	}
}


/* newsletter */
#newsletters_module.list h3 {
	padding-top: 0;
	margin-top: 0;
}

#newsletters_module.form .instructions {
	font-weight: 500;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
		padding-bottom: 15px;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/* recipes */
.recipes-blocks-searches .recipes-blocks-search-button.primary {
	background-image: none !important;
	min-width: auto;
	background-color: #213a7f;
}

.recipes-blocks-searches .recipes-blocks-search-button.primary:hover {
	transform: none;
}

#recipes_module.cms_list .recipes-list .recipe-entity .recipe-link {
	font-size: 0.9rem;
	line-height: 1.2rem;
	color: #333;
	font-weight: normal;
}

/* products (custom) */
.product-list .product-list-item .product-item-title .product-link,
.product-view #related-products #related-products-carousel .related-product-item a {
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.product-view #related-products #related-products-carousel.owl-carousel {
	width: 90%;
	max-width: 1000px;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
}

.product-view .description-with-image .product-pack img {
	max-height: 250px;
	width: auto;
}

.product-view #related-products #related-products-carousel.owl-carousel .owl-item {
	max-width: 25%;
}

#related-products .owl-carousel .owl-stage {
	display: flex;
}

.product-view #related-products #related-products-carousel.owl-carousel .related-product-item img {
	max-height: 150px;
}

.recipe-description .info-item {
	color: #196b19;
}

.recipes-blocks-searches .recipes-blocks-search-button.primary {
	background-color: #196b19;
}

/* gdpr */
.consent-form-visible .header-container {
	z-index: 1 !important;
}

#cookie-consent-form-container form .consent-header {
	text-align: center;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
	margin: 0 auto;
}


#cookie-consent-container .consent-actions a {
	border-bottom: 0;
	text-decoration: underline;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-weight: 700;
	text-align: center;
}

#cookie-consent-container .consent-actions a:hover {
	color: #8ab0dd;
}

#cookie-consent-container .consent-actions a.consent-all-trigger,
#cookie-consent-container .consent-actions a.reject-all-trigger {
	text-decoration: none;
	background-image: url('/20200408172800/local/public/shared/assets/images/websites/cydadietcharalambideschristis/Underline-04.png');
	padding-bottom: 18px !important;
	background-position: center bottom;
	font-size: 13px;
	background-color: unset;
	color: #fff;
}

#cookie-consent-container .consent-actions a.consent-all-trigger:hover,
#cookie-consent-container .consent-actions a.reject-all-trigger:hover {
	color: #fff;
	background-color: unset;
}

#cookie-consent-container {
	box-shadow: 0 -5px 0 0 #8ab0dd;
}

#cookie-consent-container.minimized {
	box-shadow: none;
}

#cookie-consent-container .consent-disclaimer {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.8rem;
	line-height: 1.3rem;
}

#cookie-consent-container {
	display: flex;
	justify-content: center;
}

#cookie-consent-container .consent-actions {
	order: 2;
	width: 340px;
}

#cookie-consent-container .consent-disclaimer {
	width: 55%;
	padding-right: 0px;
	max-width: 820px;
}


@media (max-width: 991px) {
	#cookie-consent-container {
		flex-direction: column;
	}

	#cookie-consent-container .consent-disclaimer {
		width: 90%;
		padding-right: 0px;
		max-width: 90%;
		font-size: 0.75rem;
		line-height: 1.25rem;
	}

	#cookie-consent-container .consent-actions {
		order: 2;
		width: 100%;
		text-align: center;
	}

	#cookie-consent-container .consent-actions a.consent-dismiss-trigger {
		position: absolute;
		top: 10px;
		right: 15px;
	}

	#cookie-consent-container .consent-actions a {
		margin-top: 0px;
	}

	#cookie-consent-container .consent-actions a.consent-all-trigger,
	#cookie-consent-container .consent-actions a.reject-all-trigger {
		font-size: 12px;
	}
}

@media (max-width: 479px) {
	#cookie-consent-container .consent-actions a.consent-all-trigger,
	#cookie-consent-container .consent-actions a.reject-all-trigger {
		font-size: 11px;
	}
}


/****************** Content Boxes ******************/
/***************************************************/
/* videos */
.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	max-width: 1300px;
	padding: 0px 5px;
}

.homepage-featured-video {
	float: none;
	clear: both;
	width: 100%;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}

.homepage-featured-video iframe,
.homepage-featured-video video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	border: 0px;
	height: 100%;
}


/* events */
#events_module.homepage_contentbox .event_list {
	margin-bottom: 20px;
}

.home-content-banner-section #events_module.homepage_contentbox .event_list *,
.home-content-banner-section #events_module.homepage_contentbox .event_list a,
.home-content-banner-section #events_module.homepage_contentbox .event_list a:hover {
	color: #fff;
}


/* news */
#news_module.homepage_contentbox .cms_date h3 {
	font-size: 0.9rem;
	line-height: 1rem;
	margin: 0px;
}

#news_module.homepage_contentbox .cms_title h3 {
	font-size: 1.1rem;
}

.home-content-banner-section #news_module.homepage_contentbox *,
.home-content-banner-section #news_module.homepage_contentbox a,
.home-content-banner-section #news_module.homepage_contentbox a:hover {
	color: #fff;
}


/* partners */

/* newsletter */

/* submission form */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}
