@font-face {
	font-family: Literata-Italic;
	src: url("/assets/fonts/Literata-Italic.ttf");
}

@font-face {
	font-family: Literata;
	src: url("/assets/fonts/Literata.ttf");
}

.brand {
	margin-right: 0px;
}

.heading-4 {
	font-family: "Literata", sans-serif;
}

.heading-cursive {
	font-family: "Literata", sans-serif;
}

.cursive-header {
	font-family: "Literata", sans-serif;
}

.cursive-header.slider {
	line-height: 100px !important;
	font-size: 75px !important;
	font-weight: 700;
}

.text-block-h1 {
	font-family: "Literata", sans-serif;
	font-size: 35px;
	line-height: 35px;
	padding: 0;
	margin-bottom: 0.75rem;
}

.cb-heading.footer-heading {
	font-family: "Literata", sans-serif;
	font-size: 20px;
	font-weight: bold;
	line-height: 23px;
}

.qt-data-row-text.first-col-date {
	font-family: "Literata", sans-serif;
}

.left-nav-section-title {
	font-size: 1.6rem !important;
	line-height: 1.8rem !important;
	font-family: "Literata", sans-serif;
}

.left-nav-section-title-link {
	font-size: 1.6rem !important;
	line-height: 1.8rem !important;
	font-family: "Literata", sans-serif !important;
}

.cursive-header {
	font-size: 35px;
	line-height: 55px;
}

.heading-cursive.recipes {
	font-size: 40px;
	line-height: 45px;
	font-weight: 600;
}

.image-10 {
	padding: 12px 8px;
	width: 128px;
}

@media screen and (max-width: 991px) {
	.image-10 {
		padding: 8px;
		margin-top: 0px;
		max-width: 95%;
	}

	.cursive-header.slider {
		line-height: 65px !important;
		font-size: 60px !important;
		font-weight: 700;
	}

	.text-block-h1 {
		font-size: 30px;
		line-height: 27px;
	}
}

@media screen and (max-width: 767px) {
	.cursive-header {
		font-size: 30px;
		line-height: 27px;
	}
}

@media screen and (max-width: 479px) {
	.text-block-h1,
	.cursive-header {
		font-size: 25px;
		line-height: 22px;
	}
}
